import { createModule, mutation, action, extractVuexModule } from "vuex-class-component";
import {DashCardData} from "@/views/DashCardData";


const VuexModule = createModule({
    namespaced: "ui",
    strict: false,
})

export class UIStore extends VuexModule {
    public sidebarShow: string | boolean = 'responsive';
    public sidebarMinimize: boolean = false;
    public asideShow: boolean = false;
    public darkMode: boolean = false;
    public registerState: number = 1;
    public dragging:boolean = false;
    public registerWait: boolean = false;

    public leftMinimize = false;
    public rightMinimize = false;
    public selectedLayout: string = 'default';
    public savedCardLayout: Array<DashCardData> | null = null;
    public readonly layoutOptions: string[] = ['default', 'Alphabetical >', 'Alphabetical <','Frequently used', 'Help Desk View', 'Custom Saved'];
    
    public leftShow = true;

    private readonly version = '0.1'
    
    public get currentLayout(): Array<DashCardData> {
        
        switch (this.selectedLayout){
            case 'default':
                return this.defaultLayout;
            case 'Alphabetical >':
                return this.alphabeticalLayout;
        }
        return [];
    }

    @mutation setLayout(which:string) {
        this.selectedLayout = which;
    }
    
    @mutation setWaitForRegister(which: boolean){
        this.registerWait = which;
    }
    
    @mutation setDragging(state: boolean){
        this.dragging=state;
    }

    @mutation toggleSidebarDesktop(state: any) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        this.sidebarShow = sidebarOpened ? false : 'responsive'
    }

    @mutation toggleSidebarMobile(state: any) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        this.sidebarShow = sidebarClosed ? true : 'responsive'
    }

    @mutation toggleAside() {
        this.asideShow=!this.asideShow;
    }

    @mutation openAside() {        
        this.asideShow=true;
    }

    @mutation closeAside() {        
        this.asideShow=false;
    }

    @mutation toggleDarkMode() {
        this.darkMode=!this.darkMode;
    }
    
    get alphabeticalLayout(): Array<DashCardData>{
        return [
            // { i: 11, x: 0, y: 0, w: 2, h: 8, title: "ConnectMe", description: "<ul class='pl-2'><li>Family</li><li>Minors</li><li>Dr’s</li></ul><ul class='pl-2'><li>Security Co</li><li>ERPC</li><li>Associations</li></ul>", image: "ConnectMe.svg", additionalclasses: "backgroundRing", route: "/dashboard", imageheight: 65, imagewidth: 'auto' }, //should we fetch these by webservice?
            // { i: 6, x: 0, y: 0, w: 2, h: 8, title: "HelpDesk", description: "<ul class='pl-2'><li>Create a 'Ticket' for feedback / assistance - View existing Tickets</li></ul>", image: "HelpDesk.svg", additionalclasses: "backgroundRing", route: "/dashboard/helpdesk", imageheight: 65, imagewidth: 'auto' },
            { i: 4, x: 4, y: 0, w: 2, h: 8, title: "Linked Devices & Apps", description: "<ul class='pl-2'><li>AAA Instant Connect</li></ul>", additionalclasses: "backgroundRing", route: "/dashboard/devices", imageheight: 70, imagewidth: 'auto' },
            { i: 9, x: 2, y: 0, w: 2, h: 8, title: "MyAccount", description: "<ul class='pl-2'><li>Products - Billing (Invoices etc) - Bank Details</li></ul>", image: "MyAccount.svg", additionalclasses: "backgroundRing", route: "/dashboard/myaccount", imageheight: 60, imagewidth: 'auto' },
            // { i: 10, x: 6, y: 0, w: 2, h: 8, title: "MyAssets", description: "<ul class='pl-2'><li>Possessions<li>Vehicles</li><li>Livestock</li><li>Jewelery</li></ul>", image: "MyAssets.svg", additionalclasses: "backgroundRing", route: "/dashboard/myassets", imageheight: 60, imagewidth: 'auto' },
            
            // { i: 2, x: 0, y: 8, w: 2, h: 8, title: "MyDocuments", description: "<ul class='pl-2'><li>Paperwork</li><li>Firearms Competency</li><li>Licences</li><li>Terms & Conditions</li><li>Policy Docs</li></ul>", image: "MyDocuments.svg", additionalclasses: "backgroundRing", route: "/dashboard/mydocuments", imageheight: 65, imagewidth: 'auto' },
            // { i: 5, x: 2, y: 8, w: 2, h: 8, title: "MyEmergency", description: "<ul class='pl-2'><li>Biometrics</li><li>Description</li><li>Photo</li><li>NB Information</li><li>My Emergency procedure (preplanned)</li><li>PathFinder</li></ul>", image: "MyEmergency.svg", additionalclasses: "backgroundRing", route: "/dashboard/myemergency", imageheight: 60, imagewidth: 'auto' },
            // { i: 1, x: 4, y: 8, w: 2, h: 8, title: "MyHealth", description: "<ul class='pl-2'><li>Medical</li><li>Health Info</li><li>Pills</li><li>Bio (health wise)</li></ul>", image: "MyHealth.svg", additionalclasses: "backgroundRing", route: "/dashboard/myhealth", imageheight: 55, imagewidth: 'auto' },
            { i: 7, x: 0, y: 0, w: 2, h: 8, title: "MyProfile", description: "<ul class='pl-2'><li>Contact Details - Personal info</li></ul>", image: "MyProfile.svg", additionalclasses: "backgroundRing", route: "/dashboard/myprofile", imageheight: 55, imagewidth: 'auto' },
            
            // { i: 0, x: 0, y: 16, w: 2, h: 8, title: "Reminders", description: "<ul class='pl-2'><li>Licence Disk</li><li>Pets Innoculations<li>Renewals</li></ul>", image: "Default%20&%20reminders.svg", additionalclasses: "backgroundRing", route: "/dashboard/reminders", imageheight: 70, imagewidth: 'auto' },
            // { i: 3, x: 2, y: 16, w: 2, h: 8, title: "Vehicle Check Centre", description: "<ul class='pl-2'><li>Tyres, Fuel & Maintenance</li><li>Vehicle Scanner</li><li>Suspicious Vehicle Check</li></ul>", image: "CoDriver.svg", additionalclasses: "backgroundRing", route: "/dashboard/vehiclecheck", imageheight: 70, imagewidth: 'auto' },
        ]
    }
    
    get defaultLayout(): Array<DashCardData>{
        return [
            // { i: 0, x: 0, y: 0, w: 2, h: 8, title: "Reminders", description: "<ul class='pl-2'><li>Licence Disk</li><li>Pets Innoculations<li>Renewals</li></ul>", image: "Default%20&%20reminders.svg", additionalclasses: "backgroundRing", route: "/dashboard/reminders", imageheight: 70, imagewidth: 'auto' },
            // { i: 1, x: 2, y: 0, w: 2, h: 8, title: "MyHealth", description: "<ul class='pl-2'><li>Medical</li><li>Health Info</li><li>Pills</li><li>Bio (health wise)</li></ul>", image: "MyHealth.svg", additionalclasses: "backgroundRing", route: "/dashboard/myhealth", imageheight: 55, imagewidth: 'auto' },
            // { i: 2, x: 4, y: 0, w: 2, h: 8, title: "MyDocuments", description: "<ul class='pl-2'><li>Paperwork</li><li>Firearms Competency</li><li>Licences</li><li>Terms & Conditions</li><li>Policy Docs</li></ul>", image: "MyDocuments.svg", additionalclasses: "backgroundRing", route: "/dashboard/mydocuments", imageheight: 65, imagewidth: 'auto' },
            // { i: 3, x: 6, y: 0, w: 2, h: 8, title: "Vehicle Check Centre", description: "<ul class='pl-2'><li>Tyres, Fuel & Maintenance</li><li>Vehicle Scanner</li><li>Suspicious Vehicle Check</li></ul>", image: "CoDriver.svg", additionalclasses: "backgroundRing", route: "/dashboard/vehiclecheck", imageheight: 70, imagewidth: 'auto' },

            { i: 4, x: 4, y: 0, w: 2, h: 8, title: "Linked Devices & Apps", description: "<ul class='pl-2'><li>AAA Instant Connect</li></ul>", additionalclasses: "backgroundRing", route: "/dashboard/devices", imageheight: 70, imagewidth: 'auto' },
            // { i: 5, x: 2, y: 8, w: 2, h: 8, title: "MyEmergency", description: "<ul class='pl-2'><li>Biometrics</li><li>Description</li><li>Photo</li><li>NB Information</li><li>My Emergency procedure (preplanned)</li><li>PathFinder</li></ul>", image: "MyEmergency.svg", additionalclasses: "backgroundRing", route: "/dashboard/myemergency", imageheight: 60, imagewidth: 'auto' },
            // { i: 6, x: 4, y: 8, w: 2, h: 8, title: "HelpDesk", description: "<ul class='pl-2'><li>Create a 'Ticket' for feedback / assistance - View existing Tickets</li></ul>", image: "HelpDesk.svg", additionalclasses: "backgroundRing", route: "/dashboard/helpdesk", imageheight: 65, imagewidth: 'auto' },
            { i: 7, x: 0, y: 0, w: 2, h: 8, title: "MyProfile", description: "<ul class='pl-2'><li>Contact Details - Personal info</li></ul>", image: "MyProfile.svg", additionalclasses: "backgroundRing", route: "/dashboard/myprofile", imageheight: 55, imagewidth: 'auto' },

            
            { i: 9, x: 2, y: 0, w: 2, h: 8, title: "MyAccount", description: "<ul class='pl-2'><li>Products - Billing (Invoices etc) - Bank Details</li></ul>", image: "MyAccount.svg", additionalclasses: "backgroundRing", route: "/dashboard/myaccount", imageheight: 60, imagewidth: 'auto' },
            // { i: 10, x: 2, y: 16, w: 2, h: 8, title: "MyAssets", description: "<ul class='pl-2'><li>Possessions<li>Vehicles</li><li>Livestock</li><li>Jewelery</li></ul>", image: "MyAssets.svg", additionalclasses: "backgroundRing", route: "/dashboard/myassets", imageheight: 60, imagewidth: 'auto' },
            // { i: 11, x: 4, y: 16, w: 2, h: 8, title: "ConnectMe", description: "<ul class='pl-2'><li>Family</li><li>Minors</li><li>Dr’s</li></ul><ul class='pl-2'><li>Security Co</li><li>ERPC</li><li>Associations</li></ul>", image: "ConnectMe.svg", additionalclasses: "backgroundRing", route: "/dashboard", imageheight: 65, imagewidth: 'auto' }, //should we fetch these by webservice?
        ]
    }

    get getAsideState(){
        return this.asideShow;
    }

    get RegisterState():number{        
        return this.registerState;
    }

    set RegisterState(which:number){
        this.registerState=which;
    }

    @mutation nextRegisterState(){
        this.registerState+=1;
        if (this.registerState>3){
            this.registerState=1;
        }
    }
}
