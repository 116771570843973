import Vue from 'vue'
import Router from 'vue-router'
import RegisterOne from "@/views/viewcomponents/RegisterOne.vue";
import RegisterOTP from "@/views/viewcomponents/RegisterOTP.vue";
import RegisterPassword from "@/views/viewcomponents/RegisterPassword.vue";
import MyEmergencyPage from "@/views/pages/MyEmergencyPage.vue";
import ShopPage from "@/views/pages/ShopPage.vue";
import ProductPage from "@/views/pages/ProductPage.vue";
import MyAccountPage from "@/views/pages/MyAccountPage.vue";
import MyInvoicePage from "@/views/pages/MyInvoicePage.vue";
import CartPage from "@/views/pages/CartPage.vue";
import MyCaseBookPage from "@/views/pages/MyCaseBookPage.vue";
import ComingSoonPage from "@/views/pages/ComingSoonPage.vue";
import AssetCategoryPage from "@/views/pages/AssetCategoryPage.vue";
import AddEditInfoPage from "@/views/pages/AddEditInfoPage.vue";
import MyHealthPage from "@/views/pages/MyHealthPage.vue";
import MyProductPage from "@/views/pages/MyProductPage.vue";
import HelpDesk from "@/views/pages/HelpDesk.vue";
import LinkedAppsPage from "@/views/pages/LinkedAppsPage.vue";
import VehicleCheck from "@/views/pages/VehicleCheck.vue";
import SignInWithEmail from "@/views/pages/SignInWithEmail.vue";
import firebase from "firebase/app";
import "firebase/auth";

const DashContainer = () => import('../containers/DashContainer.vue');
const TheContainer = () => import('../containers/TheContainer.vue');
const Home = () => import('../views/Home.vue');
const About = () => import('../views/About.vue');
const Dashboard = () => import('../views/Dashboard.vue');
const ReminderPage = () => import('../views/pages/ReminderPage.vue');
const ErpcDesk = () => import('../views/pages/ErpcDesk.vue');
const MyProfilePage = () => import('../views/pages/MyProfilePage.vue');
const MyDocumentsPage = () => import('../views/pages/MyDocumentsPage.vue');
const DocumentList = () => import('../views/pages/DocumentList.vue');
const MyDocumentsMain = () => import('../views/pages/MyDocumentsMain.vue');
const MyAssets = () => import('../views/pages/MyAssetsPage.vue');
const QrLink = () => import('../views/pages/QrPage.vue');
const QrLinkIndividual = () => import('../views/pages/QrPageIndividual.vue');
const ResetPasswordPage = () => import('../views/pages/ResetPage.vue');
const ContactUs = () => import('../views/ContactUs.vue');
const News = () => import('../views/News.vue');
const Projects = () => import('../views/Projects.vue');
const Products = () => import('../views/Products.vue');
Vue.use(Router);

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  
  routes: [
    {
      path: '/',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'Home',
          component: Home
        },
        {
          path: 'signinwithlink',
          name: 'SignInByEmail',
          component: SignInWithEmail
        },
        {
          path: 'resetpassword',
          name: 'Reset Password',
          component: ResetPasswordPage
        },
        {
          path: 'about',
          name: 'About',
          component: About,
          meta: {
            requiresAuth: false,
          },
          
        },  
        {
          path: 'projects',
          name: 'Projects',
          component: Projects,
          meta: {
            requiresAuth: false,
          },
          
        },  
        {
          path: 'Products',
          name: 'Products',
          component: Products,
          meta: {
            requiresAuth: false,
          },
          
        },  
        {
          path: 'news',
          name: 'News',
          component: News,
          meta: {
            requiresAuth: false,
          },
          
        },  
        {
          path: 'contactus',
          name: 'ContactUs',
          component: ContactUs,
          meta: {
            requiresAuth: false,
          },
          
        },  
      ]
    },
    {
      path: '/qrlink/:erpcid',
      component: QrLink,
      props: ({ params }) => ({ erpcid: params.erpcid }),
    },
    {
      path: '/qrlinkprivate/:erpcid',
      component: QrLinkIndividual,
      props: ({ params }) => ({ erpcid: params.erpcid }),
    },
    {
      path: '/dashboard',
      component: DashContainer,
      children: [
        {
          path: '',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'reminders',
          name: 'Reminders',
          component: ReminderPage,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'helpdesk',
          name: 'HelpDesk',
          component: HelpDesk,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'myprofile',
          name: 'MyProfile',
          component: MyProfilePage,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'myhealth',
          name: 'MyHealth',
          component: MyHealthPage,
          meta: {
            requiresAuth: true,
          },
        },            
        {
          path: 'myhealth/:view',
          name: 'MyHealth',
          component: AddEditInfoPage,
          props: ({ params }) => ({ view: params.view }),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'vehiclecheck',
          name: 'VehicleCheck',
          component: VehicleCheck,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'myassets',
          name: 'MyAssets',
          component: MyAssets,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'myasset/:assetid',
          name: 'MyAsset',
          component: AssetCategoryPage,
          props: ({ params }) => ({ assetid: params.assetid }),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'devices',
          name: 'LinkedDevices',
          component: LinkedAppsPage,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'casebook',
          name: 'CaseBook',
          component: MyCaseBookPage,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'myemergency',
          name: 'MyEmergency',
          component: MyEmergencyPage,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'mydocuments',
          name: 'MyDocuments',
          component: MyDocumentsPage,
          children: [
            {
              path: '/',
              name: 'DocumentList',
              component: MyDocumentsMain,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'agreements',
              name: 'DocumentList',
              component: DocumentList,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'saved',
              name: 'DocumentList',
              component: DocumentList,
              meta: {
                requiresAuth: true,
              },
            },
          ]
        },
        {
          path: 'shop',
          name: 'Shop',
          component: ShopPage
        },
        {
          path: 'myinvoices',
          name: 'MyInvoices',
          component: MyInvoicePage,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'myaccount',
          name: 'MyAccount',
          component: MyAccountPage,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'cart',
          name: 'MyCart',
          component: CartPage
        },
        {
          path: 'product/:productId',
          name: 'Product',
          component: ProductPage,
          props: ({ params }) => ({ productId: Number.parseInt(params.productId, 10) || 0 })
        },
        {
          path: 'myproduct/:productId',
          name: 'MyProduct',
          component: MyProductPage,
          props: ({ params }) => ({ productId: Number.parseInt(params.productId, 10) || 0 })
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {    
  // Check if the user is logged in
  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      // If logged in, proceed to the route
      next();
    } else {
      // If not logged in and trying to access a protected route, redirect to the home page
      if (to.path !== '/' && to.path !== '/resetpassword' && to.meta?.requiresAuth) {
        console.log('nah fam');
        next('/');
      } else {
        // If trying to access the home page, allow
        next();
      }
    }
  });
});

export default router
