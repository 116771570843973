












































import { vxm } from "@/store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class GenericGridCard extends Vue {
  @Prop(String) readonly route: string | undefined;
  @Prop(String) readonly title: string | undefined;
  @Prop(String) readonly description: string | undefined;
  @Prop({ default: 'Default%20&%20reminders.svg' }) readonly image!: string;
  @Prop({ default: false }) readonly subimage!: string;
  @Prop({ default: false }) readonly height!: string;
  @Prop({ default: '' }) readonly additionalclasses!: string;
  @Prop({ default: '' }) readonly disableclick!: string;
  @Prop({ default: '' }) readonly imageheight!: string;
  @Prop({ default: '' }) readonly imagewidth!: string;

  private ui = vxm.ui;
  private publicPath = process.env.VUE_APP_STATIC_DIR;
  private showContextMenu: boolean = false;

  private navigate() {

    if (this.disableclick) {
      return;
    }

    if (this.showContextMenu) {
      this.showContextMenu = false;
      return;
    } 

    this.$router.push(this.route!);
  }

  private openContextMenu() {
    window.addEventListener("contextmenu", e => e.preventDefault());
    this.showContextMenu = true;
  }

  private closeContextMenu() {   
    window.addEventListener("contextmenu", e => e.preventDefault()); 
  }
}

