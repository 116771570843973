<template>
    <CCard>
        <CCardHeader>
            CaseBook
        </CCardHeader>
        <CRow>
            <CCol col="8">
                <CCardBody>
                    <GmapMap ref="mapRef"
                            :center.sync="center"
                            :zoom="13"
                            style="height: 800px"
                    >
                        <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                                        @closeclick="infoWinOpen=false">
                            <CLink :href="infoLink" target="_blank">{{infoContent}}</CLink>
                        </GmapInfoWindow>
                        <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :label="m.label"
                                :title="m.title"
                                :clickable="true"
                                :draggable="m.draggable"
                                @click="toggleInfoWindow(m, index)"
                        />
                    </GmapMap>
                </CCardBody>
            </CCol>
            <CCol>
                <CCardBody>
                    <CCardHeader>
                        Search Parameters
                    </CCardHeader>
                  <gmap-autocomplete
                      
                      style="width:100%"
                      @place_changed="setPlace"
                      :options="{
                      componentRestrictions: { country: 'za' },
                      strictBounds: false,
                      types: ['(cities)'],
                    }">
                  </gmap-autocomplete>
                    <multiselect style="margin-top:30px"
                        placeholder="Case Types"
                            :close-on-select="false"
                            v-model="categories"
                            :options="displayCategoryTypes"
                            :multiple="true"
                            label="label"
                            track-by="label"
                    />
                  <CustomRangeSlider :sliderval.sync="searchRadius" />
                    
                    <CSelect style="margin-top:30px"
                            placeholder="DateRange"
                            :options="$options.dateRangeOptions"
                            :value.sync="dateRange"

                    />

                    <CButton @click="fetchFresh">VIEW</CButton>
                </CCardBody>
            </CCol>
        </CRow>
    </CCard>
</template>

<script>
    import * as VueGoogleMaps from 'vue2-google-maps'
    import Vue from 'vue'
    import Multiselect from 'vue-multiselect';
    import * as CaseStuff from "@shared_vue/openapi/caseapi";
    import 'vue-multiselect/dist/vue-multiselect.min.css'
    import CustomRangeSlider from "@/views/viewcomponents/CustomRangeSlider";
    
    
    Vue.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyAruewTbxyLgrwiDkWc0NJc55QR5GsWvk4',
          libraries: 'places'
            // key: ''
            // To use the Google Maps JavaScript API, you must register your app project on the Google API Console and get a Google API key which you can add to your app
            // v: 'OPTIONAL VERSION NUMBER',
            // libraries: 'places', //// If you need to use place input
        }
    });

    export default {
        name: 'MyCaseBookPage',
        components: {Multiselect, CustomRangeSlider},
        mounted: function() { //fetch
          this.fetchCaseTypes()
          this.fetchFresh()
          this.getLocation()
        },
        data() { //move data to mock API
            return {
                caseAPI : CaseStuff.CaseApiFactory({basePath:process.env.VUE_APP_CASE_API_URL},process.env.VUE_APP_CASE_API_URL),
                provinces: null,
                cities: null,
                place: null,
                categories: null,
              mylocation:null,
                availableCategories:null,
                fetchedCases: null,
                dateRange: null,
                center: {lat: -29.7857789, lng: 30.7703669},
                searchlat: null,
                searchlng: null,
                searchRadius: 50,
                markers: [{ //do we need custom markers?
                    position: {lat: -29.7857789, lng: 30.7703669},
                    label: 'B',
                    draggable: false,
                    title: 'Break-In',
                    www: 'https://members.trackbox.world/'
                }, {
                    position: {lat: -29.7862827, lng: 30.7659364},
                    label: 'M',
                    draggable: false,
                    title: 'Murder',
                    www: 'https://members.trackbox.world/'
                },],
                infoContent: '',
                infoLink: '',
                infoWindowPos: {
                    lat: 0,
                    lng: 0
                },
                infoWinOpen: false,
                currentMidx: null,
                // optional: offset infowindow so it visually sits nicely on top of our marker
                infoOptions: {
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                }
            }
        },
      computed:{
        selectedProvinces: function() {
          return this.provinces?.map(p=>p.value)??null
        },
        selectedCities: function() {
          return this.cities?.map(p=>p.value)??null
        },
        selectedCategories: function() {
          return this.categories?.map(p=>p.value)??null
        },
        displayCategoryTypes: function (){
          return this.availableCategories?.map(o=>({value:o.id,label:o.text}))??[];
        },
        // markers: function(){
        //   return this.fetchedCases?.map((el)=>({
        //     // position: {lat: Number(el.latitude), lng: Number(el.longitude)},
        //     position: {lat: -29.7857789, lng: 30.7703669},
        //     label: el.description,
        //         draggable: false,
        //         title: el.title,
        //         www: 'https://members.trackbox.world/'
        //   }))??[]  
        // }
      },
        methods: {
          setPlace(place) {
            console.log(place)
            this.place = place
            this.searchlng = place.geometry.location.lng();
            this.searchlat = place.geometry.location.lat();
            this.scrollToPlace();
          },
          setClientLocation(position) {
            this.mylocation = position;
            this.center = {lat: position.coords.latitude, lng: position.coords.longitude}
          },
          getLocation() {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(this.setClientLocation);
            } else {
              x.innerHTML = "Geolocation is not supported by this browser.";
            }
          },
          scrollToPlace() {
            if (this.place) {
              
                let position= this.place.geometry.location;
                let bounds = this.place.geometry.viewport;
                this.panTo(this.place.geometry.location.lat(), this.place.geometry.location.lng());
            }
          },
          panToBounds(b) {
            const southwest = { lat: b.Bb.lo, lng: b.Va.lo };
            const northeast = { lat: b.Bb.hi, lng: b.Va.hi };
            const newBounds = new google.maps.LatLngBounds(southwest, northeast);

            

            this.$refs.mmm.panToBounds(newBounds);
          },
          panTo(lat, lng) {
            this.$refs.mmm.panTo({
              lat: lat,
              lng: lng
            });
          },
          fetchCaseTypes(){
            var outerthis = this;
            this.caseAPI.caseGetCaseCategoryOptions().then((res)=>{
              console.log('got case types')
              console.log(res)
              outerthis.availableCategories = res.data;
            })
          },
          async fetchFresh(){
            console.log('fetching cases')
            try {
              let filter = {
                caseTypes: this.selectedCategories,
                dateRange: this.dateRange,
                kmRadius: this.searchRadius,
                latitude: this.searchlat,
                longitude: this.searchlng
              };
              console.log('filter: ' + JSON.stringify(filter))
              let res = await this.caseAPI.caseGetCaseBookCases(filter);
              console.log('Got cases')
              if (res.data && res.data.caseBookPoints && res.data.caseBookPoints.length > 0) {
                // this.fetchedCases = res.data?.caseBookPoints ?? null;
                this.markers = res.data?.caseBookPoints?.map((el)=>({
                      position: {lat: Number(el.latitude), lng: Number(el.longitude)},
                      // position: {lat: -29.5356885, lng: 31.2146259},
                      label: el.description,
                          draggable: false,
                          title: el.title,
                          www: 'https://members.trackbox.world/'
                    }))
                this.center = {
                  lat: res.data.centerLatitude,
                  lng: res.data.centerLongitude
                };
              } else {
                console.log('empty, skipping')
              }
            } catch (e) {
              console.log(`error: ${e}`)
            }
          },
            toggleInfoWindow(marker, idx) {
                this.infoWindowPos = marker.position
                this.infoContent = marker.title
                this.infoLink = marker.www
                // check if its the same marker that was selected if yes toggle
                if (this.currentMidx === idx) {
                    this.infoWinOpen = !this.infoWinOpen
                } else {
                    // if different marker set infowindow to open and reset current marker index
                    this.currentMidx = idx
                    this.infoWinOpen = true
                }
            }
        },
        provinceOptions: [
            { value: 'KZN', label: 'KwaZulu-Natal' },
            { value: 'GP', label: 'Gauteng' },
            { value: 'EC', label: 'Eastern Cape'},
            { value: 'WC', label: 'Western Cape' },
            { value: 'NC', label: 'Northern Cape' },
            { value: 'LM', label: 'Limpopo' },
            { value: 'MP', label: 'Mpumalanga' },
            { value: 'FS', label: 'Free State' },
            { value: 'NW', label: 'North West' },
            ],
        cityOptions:[
            {value:'HillCrest', label:'HillCrest'},
            {value:'Durban', label:'Durban'},
            {value:'Ballito', label:'Ballito'},
        ],
        categoryOptions:[
            {value:'1', label:'Crime'},
            {value:'2', label:'Bees'},
        ],
      dateRangeOptions:[
        {value:'today', label:'today'},
        {value:'yesterday', label:'yesterday'},
      ]
        
        
    }
    
</script>

<style scoped>

</style>
