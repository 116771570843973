



















































































import {Component, Vue } from 'vue-property-decorator';
import { InputField} from "../../types/InputField";
import SearchAutoComplete from "@shared_vue/components/searchautocomplete/SearchAutoComplete.vue";


@Component({
  components: {
    SearchAutoComplete
  },
})
export default class DynamicInputModal extends Vue {


  public modalVisible= false;
  public el: InputField[] = [];
  public deleteVisible = false;
  public name?: string;
  
  
  public showModal(el: InputField[], extras: any = {}, name: string) {
    this.el = el;
    this.modalVisible = true;
    this.name = name

    const deleteButton = document.getElementById('deleteButton');
    console.log(extras?.deleteVisible ? 'deleteVisible': 'no delete visible');
    extras?.deleteVisible ? deleteButton?.classList.remove('invisible'): deleteButton?.classList.add('invisible');
  };
  
  public saveItem() {
    console.log('Save data');
    this.$emit('saveclicked', this.name)
    this.hideModal();
  }
  public deleteItem() {
    console.log('delete data');
    this.$emit('deleteclicked', this.name)
    this.hideModal();
  }
  public hideModal() {
    this.modalVisible = false;
  }


}
