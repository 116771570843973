








































import {Component, Vue, Prop, PropSync, Watch} from 'vue-property-decorator';
import {SelectOption} from "@shared_vue/types/SelectOption";



@Component({inheritAttrs: false})
export default class SearchAutocomplete extends Vue {
  // @Prop(Array) readonly items: Array<string> | undefined;
  @Prop(Array) readonly indexedItems: Array<SelectOption> | undefined;
  @Prop(Boolean) readonly isAsync: boolean | undefined;

  private isOpen: boolean = false;
  private results: Array<string> = [];
  private search: string = '';
  private isLoading: boolean = false;
  private arrowCounter: number = -1;
  
  private get items(): Array<string> | undefined{
    return this.indexedItems?.map(el=>el.label);
  }

  @Watch("items")
  onIdToken(value: Array<string>, oldValue: Array<string>) {
    if (value.length !== oldValue.length) {
      this.results = value;
      this.isLoading = false;

    }
  }

  private handleClickOutside(event: any) {
    if (!this.$el.contains(event.target)) {
      this.isOpen = false;
      this.arrowCounter = -1;
    }
  }

  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  }

  destroyed() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  private setResult(result: string) {
    this.search = result;
    this.isOpen = false;
    this.onChosen();
  }
  
  private filterResults() {
    this.results = this.items!.filter((item) => {
      return item?.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
    });
  }
  
  private onChosen(){
    this.$emit('selected', this.indexedItems![this.items!.indexOf(this.search)]);
  }
  
  private onChange() {
    this.$emit('input', this.search);

    if (this.isAsync) {
      this.isLoading = true;
    } else {
      this.filterResults();
      this.isOpen = true;
    }
  }
  
  private onArrowDown() {
    if (this.arrowCounter < this.results.length) {
      this.arrowCounter = this.arrowCounter + 1;
    }
  }

  private onArrowUp() {
    if (this.arrowCounter > 0) {
      this.arrowCounter = this.arrowCounter - 1;
    }
  }

  private onEnter() {
    this.search = this.results[this.arrowCounter];
    this.isOpen = false;
    this.arrowCounter = -1;
    this.onChosen();
  }

}
