/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    profileId: string;
    /**
     * 
     * @type {Profile}
     * @memberof Account
     */
    profile: Profile;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    name: string;
    /**
     * 
     * @type {Bank}
     * @memberof Account
     */
    bank: Bank;
    /**
     * 
     * @type {AccountType}
     * @memberof Account
     */
    accountType: AccountType;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    accountNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    accountHolder: string;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    debitDay: number;
    /**
     * 
     * @type {AccountStatus}
     * @memberof Account
     */
    accountStatus: AccountStatus;
}
/**
 * 
 * @export
 * @interface AccountStatus
 */
export interface AccountStatus {
    /**
     * 
     * @type {string}
     * @memberof AccountStatus
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AccountStatus
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AccountStatus
     */
    description: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AccountType {
    Savings = 'Savings',
    Cheque = 'Cheque',
    Transmission = 'Transmission'
}

/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    confidence?: number;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    province?: string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressTypeId?: string;
    /**
     * 
     * @type {AddressType}
     * @memberof Address
     */
    addressType?: AddressType;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    unitNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    complexName?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    suburb?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postalCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    isPrimary?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    confirmed?: boolean;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof Address
     */
    contacts?: Array<Contact>;
    /**
     * 
     * @type {Point}
     * @memberof Address
     */
    geoLocation?: Point;
}
/**
 * 
 * @export
 * @interface AddressDTO
 */
export interface AddressDTO {
    /**
     * 
     * @type {number}
     * @memberof AddressDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    addressTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    ownerGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    unitNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    complexName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    suburb?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    provinceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    postalCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDTO
     */
    isPrimary?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDTO
     */
    confirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    longitude: string;
}
/**
 * 
 * @export
 * @interface AddressType
 */
export interface AddressType {
    /**
     * 
     * @type {string}
     * @memberof AddressType
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AddressType
     */
    text: string;
    /**
     * 
     * @type {Status}
     * @memberof AddressType
     */
    status: Status;
}
/**
 * 
 * @export
 * @interface Api
 */
export interface Api {
    /**
     * 
     * @type {string}
     * @memberof Api
     */
    name: string;
    /**
     * 
     * @type {Array<Statement>}
     * @memberof Api
     */
    statements: Array<Statement>;
}
/**
 * 
 * @export
 * @interface App
 */
export interface App {
    /**
     * 
     * @type {string}
     * @memberof App
     */
    guid: string;
    /**
     * 
     * @type {number}
     * @memberof App
     */
    licenseId?: number;
    /**
     * 
     * @type {Device}
     * @memberof App
     */
    device: Device;
    /**
     * 
     * @type {string}
     * @memberof App
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof App
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof App
     */
    lastCommunication?: string;
    /**
     * 
     * @type {string}
     * @memberof App
     */
    pushToken?: string;
    /**
     * 
     * @type {JsonDocument}
     * @memberof App
     */
    appInfo?: JsonDocument;
    /**
     * 
     * @type {License}
     * @memberof App
     */
    linkedLicense?: License;
}
/**
 * 
 * @export
 * @interface AppDTO
 */
export interface AppDTO {
    /**
     * 
     * @type {string}
     * @memberof AppDTO
     */
    guid?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDTO
     */
    appID?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDTO
     */
    deviceID?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDTO
     */
    pushToken?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDTO
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    description?: string;
    /**
     * 
     * @type {Profile}
     * @memberof Asset
     */
    owner: Profile;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    profileId: string;
    /**
     * 
     * @type {Insurer}
     * @memberof Asset
     */
    insurer?: Insurer;
    /**
     * 
     * @type {AssetType}
     * @memberof Asset
     */
    assetType: AssetType;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    assetTypeId: string;
    /**
     * 
     * @type {AssetProperties}
     * @memberof Asset
     */
    properties?: AssetProperties;
}
/**
 * 
 * @export
 * @interface AssetProperties
 */
export interface AssetProperties {
    /**
     * 
     * @type {Array<KeyValue>}
     * @memberof AssetProperties
     */
    props: Array<KeyValue>;
}
/**
 * 
 * @export
 * @interface AssetType
 */
export interface AssetType {
    /**
     * 
     * @type {string}
     * @memberof AssetType
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AssetType
     */
    text: string;
    /**
     * 
     * @type {Status}
     * @memberof AssetType
     */
    status: Status;
}
/**
 * 
 * @export
 * @interface Bank
 */
export interface Bank {
    /**
     * 
     * @type {number}
     * @memberof Bank
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    shortName: string;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    longName: string;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    globalBranch: string;
}
/**
 * 
 * @export
 * @interface Battery
 */
export interface Battery {
    /**
     * 
     * @type {boolean}
     * @memberof Battery
     */
    is_charging?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Battery
     */
    level?: number;
}
/**
 * 
 * @export
 * @interface Branch
 */
export interface Branch {
    /**
     * 
     * @type {number}
     * @memberof Branch
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Branch
     */
    companyId: number;
    /**
     * 
     * @type {Company}
     * @memberof Branch
     */
    company: Company;
    /**
     * 
     * @type {Address}
     * @memberof Branch
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    branchName?: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    callCenterNo?: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    companyLogo?: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    provinceId?: string;
    /**
     * 
     * @type {State}
     * @memberof Branch
     */
    province?: State;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    sectorId?: string;
    /**
     * 
     * @type {Sector}
     * @memberof Branch
     */
    sector?: Sector;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    landLine?: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    emergencyContactNo?: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    website?: string;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Branch
     */
    documents?: Array<Document>;
}
/**
 * 
 * @export
 * @interface BulkStateDTO
 */
export interface BulkStateDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof BulkStateDTO
     */
    caseIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof BulkStateDTO
     */
    status: string;
}
/**
 * 
 * @export
 * @interface BulkUserStateDTO
 */
export interface BulkUserStateDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUserStateDTO
     */
    userIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BulkUserStateDTO
     */
    status: string;
}
/**
 * 
 * @export
 * @interface CallConnectDTO
 */
export interface CallConnectDTO {
    /**
     * 
     * @type {string}
     * @memberof CallConnectDTO
     */
    operator?: string;
    /**
     * 
     * @type {string}
     * @memberof CallConnectDTO
     */
    channel?: string;
    /**
     * 
     * @type {number}
     * @memberof CallConnectDTO
     */
    caseId: number;
    /**
     * 
     * @type {string}
     * @memberof CallConnectDTO
     */
    caseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CallConnectDTO
     */
    appId?: string;
    /**
     * 
     * @type {string}
     * @memberof CallConnectDTO
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CallConnectDTO
     */
    imageUrl?: string;
}
/**
 * 
 * @export
 * @interface Cart
 */
export interface Cart {
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    version: string;
    /**
     * 
     * @type {CartState}
     * @memberof Cart
     */
    state: CartState;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    updated: string;
    /**
     * 
     * @type {Profile}
     * @memberof Cart
     */
    owner?: Profile;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    profileId?: string;
    /**
     * 
     * @type {Array<CartProduct>}
     * @memberof Cart
     */
    products?: Array<CartProduct>;
}
/**
 * 
 * @export
 * @interface CartProduct
 */
export interface CartProduct {
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    cartId: number;
    /**
     * 
     * @type {Cart}
     * @memberof CartProduct
     */
    cart: Cart;
    /**
     * 
     * @type {ProductOffering}
     * @memberof CartProduct
     */
    productOffering: ProductOffering;
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    productOfferingId: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CartState {
    Open = 'Open',
    Closed = 'Closed',
    Paid = 'Paid',
    Unpaid = 'Unpaid'
}

/**
 * 
 * @export
 * @interface Case
 */
export interface Case {
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    ownerId?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    officerId?: string;
    /**
     * 
     * @type {Profile}
     * @memberof Case
     */
    caseOfficer?: Profile;
    /**
     * 
     * @type {Profile}
     * @memberof Case
     */
    caseComplainant?: Profile;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    agoraFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    reported?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    opened?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    closed?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    reminder?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    caseNumber: string;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    caseTypeId?: number;
    /**
     * 
     * @type {CaseType}
     * @memberof Case
     */
    caseType?: CaseType;
    /**
     * 
     * @type {CaseStatus}
     * @memberof Case
     */
    status: CaseStatus;
    /**
     * 
     * @type {Point}
     * @memberof Case
     */
    location?: Point;
    /**
     * 
     * @type {Address}
     * @memberof Case
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    sapsCaseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    sapsStationIssuingCase?: string;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    originalBranchId?: number;
    /**
     * 
     * @type {Branch}
     * @memberof Case
     */
    originalBranch?: Branch;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    currentBranchId?: number;
    /**
     * 
     * @type {Branch}
     * @memberof Case
     */
    currentBranch?: Branch;
    /**
     * 
     * @type {boolean}
     * @memberof Case
     */
    callRequest?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    agoraId?: number;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    pushToken?: string;
    /**
     * 
     * @type {JsonDocument}
     * @memberof Case
     */
    caseData?: JsonDocument;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Case
     */
    documents?: Array<Document>;
}
/**
 * 
 * @export
 * @interface CaseBookFilterDTO
 */
export interface CaseBookFilterDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof CaseBookFilterDTO
     */
    caseTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CaseBookFilterDTO
     */
    dateRange?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseBookFilterDTO
     */
    kmRadius?: number;
    /**
     * 
     * @type {number}
     * @memberof CaseBookFilterDTO
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof CaseBookFilterDTO
     */
    longitude?: number;
}
/**
 * 
 * @export
 * @interface CaseBookResponse
 */
export interface CaseBookResponse {
    /**
     * 
     * @type {Array<CasebookCaseDTO>}
     * @memberof CaseBookResponse
     */
    caseBookPoints: Array<CasebookCaseDTO>;
    /**
     * 
     * @type {number}
     * @memberof CaseBookResponse
     */
    centerLatitude: number;
    /**
     * 
     * @type {number}
     * @memberof CaseBookResponse
     */
    centerLongitude: number;
    /**
     * 
     * @type {number}
     * @memberof CaseBookResponse
     */
    radius: number;
}
/**
 * 
 * @export
 * @interface CaseDTO
 */
export interface CaseDTO {
    /**
     * 
     * @type {number}
     * @memberof CaseDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    ownerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    contactNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    officerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    caseOfficer?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    reported?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    opened?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    closed?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    reminder?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    caseNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseDTO
     */
    caseTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    caseTypeHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    caseTypeDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    caseLogo?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    complaint?: string;
    /**
     * 
     * @type {CaseStatus}
     * @memberof CaseDTO
     */
    status?: CaseStatus;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    longitude?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    sapsCaseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    sapsStationIssuingCase?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    originalBranch?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseDTO
     */
    originalBranchId?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    currentBranch?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseDTO
     */
    currentBranchId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CaseDTO
     */
    callRequest?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CaseDTO
     */
    agoraId?: number;
    /**
     * 
     * @type {ProfileDTO}
     * @memberof CaseDTO
     */
    caseComplainant?: ProfileDTO;
    /**
     * 
     * @type {ErpcDTO}
     * @memberof CaseDTO
     */
    company?: ErpcDTO;
    /**
     * 
     * @type {JsonDocument}
     * @memberof CaseDTO
     */
    caseData?: JsonDocument;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    agoraFileName?: string;
}
/**
 * 
 * @export
 * @interface CaseFilterDTO
 */
export interface CaseFilterDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof CaseFilterDTO
     */
    caseStates?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CaseFilterDTO
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof CaseFilterDTO
     */
    pageSize?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CaseFilterDTO
     */
    caseTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CaseFilterDTO
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseFilterDTO
     */
    endDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CaseFilterDTO
     */
    defaultEnd?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CaseFilterDTO
     */
    defaultStart?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CaseStatus {
    New = 'NEW',
    Open = 'OPEN',
    Onhold = 'ONHOLD',
    Pending = 'PENDING',
    Closed = 'CLOSED',
    Reminder = 'REMINDER',
    Archived = 'ARCHIVED',
    Cancelled = 'CANCELLED'
}

/**
 * 
 * @export
 * @interface CaseType
 */
export interface CaseType {
    /**
     * 
     * @type {number}
     * @memberof CaseType
     */
    uid: number;
    /**
     * 
     * @type {string}
     * @memberof CaseType
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseType
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof CaseType
     */
    iconFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseType
     */
    status: string;
}
/**
 * 
 * @export
 * @interface CasebookCaseDTO
 */
export interface CasebookCaseDTO {
    /**
     * 
     * @type {number}
     * @memberof CasebookCaseDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CasebookCaseDTO
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CasebookCaseDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CasebookCaseDTO
     */
    caseTypeHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof CasebookCaseDTO
     */
    caseTypeDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof CasebookCaseDTO
     */
    caseTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof CasebookCaseDTO
     */
    caseLogo?: string;
    /**
     * 
     * @type {CaseStatus}
     * @memberof CasebookCaseDTO
     */
    status?: CaseStatus;
    /**
     * 
     * @type {string}
     * @memberof CasebookCaseDTO
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof CasebookCaseDTO
     */
    longitude?: string;
    /**
     * 
     * @type {string}
     * @memberof CasebookCaseDTO
     */
    address?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CasebookCaseDTO
     */
    track?: boolean;
}
/**
 * 
 * @export
 * @interface CityDTO
 */
export interface CityDTO {
    /**
     * 
     * @type {string}
     * @memberof CityDTO
     */
    provinceName: string;
    /**
     * 
     * @type {string}
     * @memberof CityDTO
     */
    provinceShort: string;
    /**
     * 
     * @type {string}
     * @memberof CityDTO
     */
    cityName: string;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    operativeApprovalEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    operativeApprovalEmailCC?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    status: string;
    /**
     * 
     * @type {Array<Branch>}
     * @memberof Company
     */
    branches?: Array<Branch>;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    partnerTypeId?: string;
    /**
     * 
     * @type {PartnerType}
     * @memberof Company
     */
    partnerType?: PartnerType;
    /**
     * 
     * @type {Array<CompanyProductType>}
     * @memberof Company
     */
    companyProductTypes?: Array<CompanyProductType>;
    /**
     * 
     * @type {Array<Sector>}
     * @memberof Company
     */
    sectors?: Array<Sector>;
}
/**
 * 
 * @export
 * @interface CompanyDTO
 */
export interface CompanyDTO {
    /**
     * 
     * @type {number}
     * @memberof CompanyDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    operativeApprovalEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    operativeApprovalEmailCC?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    partnerType?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyDTO
     */
    products?: Array<string>;
    /**
     * 
     * @type {Array<Sector>}
     * @memberof CompanyDTO
     */
    sectors?: Array<Sector>;
}
/**
 * 
 * @export
 * @interface CompanyProductType
 */
export interface CompanyProductType {
    /**
     * 
     * @type {number}
     * @memberof CompanyProductType
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyProductType
     */
    productTypeId: string;
    /**
     * 
     * @type {Company}
     * @memberof CompanyProductType
     */
    company: Company;
    /**
     * 
     * @type {ProductType}
     * @memberof CompanyProductType
     */
    productType: ProductType;
}
/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {number}
     * @memberof Contact
     */
    id: number;
    /**
     * 
     * @type {Array<Profile>}
     * @memberof Contact
     */
    profiles: Array<Profile>;
    /**
     * 
     * @type {Array<ProfileContact>}
     * @memberof Contact
     */
    profileContacts: Array<ProfileContact>;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    contactTypeOther?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Contact
     */
    isMember: boolean;
    /**
     * 
     * @type {Profile}
     * @memberof Contact
     */
    self?: Profile;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    selfGuid?: string;
    /**
     * 
     * @type {Array<Address>}
     * @memberof Contact
     */
    addresses?: Array<Address>;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    otp?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    firstNames: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    emailConfirmCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Contact
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contact
     */
    phoneConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    phonePrimary?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    phoneWork?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    phoneAlternate?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    emailMain?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    emailWork?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    primaryCell?: string;
}
/**
 * 
 * @export
 * @interface ContactDTO
 */
export interface ContactDTO {
    /**
     * 
     * @type {number}
     * @memberof ContactDTO
     */
    id?: number;
    /**
     * 
     * @type {Array<ContactType>}
     * @memberof ContactDTO
     */
    contactType: Array<ContactType>;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    contactTypeOther?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactDTO
     */
    isMember: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    selfGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    targetGuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactDTO
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactDTO
     */
    phoneConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    phonePrimary?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    phoneWork?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    phoneAlternate?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    emailMain?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    emailWork?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    firstNames?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {Array<AddressDTO>}
     * @memberof ContactDTO
     */
    addresses?: Array<AddressDTO>;
}
/**
 * 
 * @export
 * @interface ContactPreference
 */
export interface ContactPreference {
    /**
     * 
     * @type {string}
     * @memberof ContactPreference
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPreference
     */
    text: string;
    /**
     * 
     * @type {Status}
     * @memberof ContactPreference
     */
    status: Status;
}
/**
 * 
 * @export
 * @interface ContactRelationship
 */
export interface ContactRelationship {
    /**
     * 
     * @type {Array<ContactType>}
     * @memberof ContactRelationship
     */
    contactType: Array<ContactType>;
    /**
     * 
     * @type {number}
     * @memberof ContactRelationship
     */
    contactId: number;
    /**
     * 
     * @type {string}
     * @memberof ContactRelationship
     */
    profileGuid: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRelationship
     */
    ownerGuid?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ContactType {
    Self = 'SELF',
    Spouse = 'SPOUSE',
    Emergency1 = 'EMERGENCY1',
    Emergency2 = 'EMERGENCY2',
    Nextofkin = 'NEXTOFKIN',
    Dependent = 'DEPENDENT',
    MedicalAidDependent = 'MEDICAL_AID_DEPENDENT',
    Father = 'FATHER',
    Mother = 'MOTHER',
    Child = 'CHILD',
    Other = 'OTHER',
    Work = 'WORK'
}

/**
 * 
 * @export
 * @interface Coordinate
 */
export interface Coordinate {
    /**
     * 
     * @type {number}
     * @memberof Coordinate
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof Coordinate
     */
    y: number;
    /**
     * 
     * @type {number}
     * @memberof Coordinate
     */
    z: number;
    /**
     * 
     * @type {number}
     * @memberof Coordinate
     */
    m: number;
    /**
     * 
     * @type {Coordinate}
     * @memberof Coordinate
     */
    coordinateValue?: Coordinate;
}
/**
 * 
 * @export
 * @interface CoordinateSequence
 */
export interface CoordinateSequence {
    /**
     * 
     * @type {number}
     * @memberof CoordinateSequence
     */
    dimension: number;
    /**
     * 
     * @type {number}
     * @memberof CoordinateSequence
     */
    measures: number;
    /**
     * 
     * @type {number}
     * @memberof CoordinateSequence
     */
    spatial: number;
    /**
     * 
     * @type {Ordinates}
     * @memberof CoordinateSequence
     */
    ordinates: Ordinates;
    /**
     * 
     * @type {boolean}
     * @memberof CoordinateSequence
     */
    hasZ: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CoordinateSequence
     */
    hasM: boolean;
    /**
     * 
     * @type {number}
     * @memberof CoordinateSequence
     */
    zOrdinateIndex: number;
    /**
     * 
     * @type {number}
     * @memberof CoordinateSequence
     */
    mOrdinateIndex: number;
    /**
     * 
     * @type {number}
     * @memberof CoordinateSequence
     */
    count: number;
}
/**
 * 
 * @export
 * @interface CoordinateSequenceFactory
 */
export interface CoordinateSequenceFactory {
    /**
     * 
     * @type {Ordinates}
     * @memberof CoordinateSequenceFactory
     */
    ordinates: Ordinates;
}
/**
 * 
 * @export
 * @interface Coords
 */
export interface Coords {
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    accuracy?: number;
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    speed?: number;
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    speed_accuracy?: number;
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    heading?: number;
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    heading_accuracy?: number;
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    altitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    altitude_accuracy?: number;
}
/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    guid: string;
    /**
     * 
     * @type {Profile}
     * @memberof Device
     */
    profile: Profile;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    ownerGUID?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    imei?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    make?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    model?: string;
    /**
     * 
     * @type {OS}
     * @memberof Device
     */
    osType?: OS;
    /**
     * 
     * @type {Array<App>}
     * @memberof Device
     */
    installedApps?: Array<App>;
    /**
     * 
     * @type {JsonDocument}
     * @memberof Device
     */
    generalInfo?: JsonDocument;
}
/**
 * 
 * @export
 * @interface DeviceDTO
 */
export interface DeviceDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    guid?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    imei?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    make?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    model?: string;
    /**
     * 
     * @type {OS2}
     * @memberof DeviceDTO
     */
    osType?: OS2;
    /**
     * 
     * @type {Array<AppDTO>}
     * @memberof DeviceDTO
     */
    apps?: Array<AppDTO>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Dimension {
    Point = 'Point',
    Curve = 'Curve',
    Surface = 'Surface',
    Dontcare = 'Dontcare',
    True = 'True',
    False = 'False'
}

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    id: number;
    /**
     * 
     * @type {Profile}
     * @memberof Document
     */
    owner?: Profile;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    profileId?: string;
    /**
     * 
     * @type {DocumentType}
     * @memberof Document
     */
    documentType?: DocumentType;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    sizeBytes: number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    uploaded: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    storeId?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    uploadedTo?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    caseId?: number;
    /**
     * 
     * @type {Case}
     * @memberof Document
     */
    _case?: Case;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    branchId?: number;
    /**
     * 
     * @type {Branch}
     * @memberof Document
     */
    branch?: Branch;
}
/**
 * 
 * @export
 * @interface DocumentDTO
 */
export interface DocumentDTO {
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    id: number;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentDTO
     */
    documentType?: DocumentType;
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    sizeBytes: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    uploaded: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    storeId?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    uploadedTo?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    category?: string;
}
/**
 * 
 * @export
 * @interface DocumentType
 */
export interface DocumentType {
    /**
     * 
     * @type {string}
     * @memberof DocumentType
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentType
     */
    text: string;
    /**
     * 
     * @type {Status}
     * @memberof DocumentType
     */
    status: Status;
}
/**
 * 
 * @export
 * @interface Entitlement
 */
export interface Entitlement {
    /**
     * 
     * @type {string}
     * @memberof Entitlement
     */
    expiresDate: string;
    /**
     * 
     * @type {string}
     * @memberof Entitlement
     */
    gracePeriodExpiresDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Entitlement
     */
    productIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof Entitlement
     */
    productPlanIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof Entitlement
     */
    purchaseDate: string;
}
/**
 * 
 * @export
 * @interface Envelope
 */
export interface Envelope {
    /**
     * 
     * @type {boolean}
     * @memberof Envelope
     */
    isNull: boolean;
    /**
     * 
     * @type {number}
     * @memberof Envelope
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof Envelope
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof Envelope
     */
    minX: number;
    /**
     * 
     * @type {number}
     * @memberof Envelope
     */
    maxX: number;
    /**
     * 
     * @type {number}
     * @memberof Envelope
     */
    minY: number;
    /**
     * 
     * @type {number}
     * @memberof Envelope
     */
    maxY: number;
    /**
     * 
     * @type {number}
     * @memberof Envelope
     */
    area: number;
    /**
     * 
     * @type {number}
     * @memberof Envelope
     */
    minExtent: number;
    /**
     * 
     * @type {number}
     * @memberof Envelope
     */
    maxExtent: number;
    /**
     * 
     * @type {Coordinate}
     * @memberof Envelope
     */
    centre?: Coordinate;
}
/**
 * 
 * @export
 * @interface ErpcDTO
 */
export interface ErpcDTO {
    /**
     * 
     * @type {number}
     * @memberof ErpcDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    company?: string;
    /**
     * 
     * @type {number}
     * @memberof ErpcDTO
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof ErpcDTO
     */
    branchId?: number;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    branchName?: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    companyLogo?: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    contactNo?: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    landLine?: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    emergencyContactNo?: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    stateId?: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    sectorId?: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    provinceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    partnerType?: string;
    /**
     * 
     * @type {AddressDTO}
     * @memberof ErpcDTO
     */
    address?: AddressDTO;
}
/**
 * 
 * @export
 * @interface Extras
 */
export interface Extras {
    /**
     * 
     * @type {string}
     * @memberof Extras
     */
    event_type?: string;
}
/**
 * 
 * @export
 * @interface FileDetail
 */
export interface FileDetail {
    /**
     * 
     * @type {string}
     * @memberof FileDetail
     */
    fileName: string;
    /**
     * 
     * @type {boolean}
     * @memberof FileDetail
     */
    isPlayable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FileDetail
     */
    mixedAllUser: boolean;
    /**
     * 
     * @type {number}
     * @memberof FileDetail
     */
    sliceStartTime: number;
    /**
     * 
     * @type {string}
     * @memberof FileDetail
     */
    trackType: string;
    /**
     * 
     * @type {string}
     * @memberof FileDetail
     */
    uid: string;
}
/**
 * 
 * @export
 * @interface Geometry
 */
export interface Geometry {
    /**
     * 
     * @type {GeometryFactory}
     * @memberof Geometry
     */
    factory?: GeometryFactory;
    /**
     * 
     * @type {object}
     * @memberof Geometry
     */
    userData?: object;
    /**
     * 
     * @type {number}
     * @memberof Geometry
     */
    srid: number;
    /**
     * 
     * @type {PrecisionModel}
     * @memberof Geometry
     */
    precisionModel?: PrecisionModel;
    /**
     * 
     * @type {number}
     * @memberof Geometry
     */
    numGeometries: number;
    /**
     * 
     * @type {boolean}
     * @memberof Geometry
     */
    isSimple: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Geometry
     */
    isValid: boolean;
    /**
     * 
     * @type {number}
     * @memberof Geometry
     */
    area: number;
    /**
     * 
     * @type {number}
     * @memberof Geometry
     */
    length: number;
    /**
     * 
     * @type {Point}
     * @memberof Geometry
     */
    centroid?: Point;
    /**
     * 
     * @type {Point}
     * @memberof Geometry
     */
    interiorPoint?: Point;
    /**
     * 
     * @type {Point}
     * @memberof Geometry
     */
    pointOnSurface?: Point;
    /**
     * 
     * @type {Geometry}
     * @memberof Geometry
     */
    envelope?: Geometry;
    /**
     * 
     * @type {Envelope}
     * @memberof Geometry
     */
    envelopeInternal?: Envelope;
    /**
     * 
     * @type {boolean}
     * @memberof Geometry
     */
    isRectangle: boolean;
}
/**
 * 
 * @export
 * @interface GeometryFactory
 */
export interface GeometryFactory {
    /**
     * 
     * @type {PrecisionModel}
     * @memberof GeometryFactory
     */
    precisionModel?: PrecisionModel;
    /**
     * 
     * @type {CoordinateSequenceFactory}
     * @memberof GeometryFactory
     */
    coordinateSequenceFactory?: CoordinateSequenceFactory;
    /**
     * 
     * @type {number}
     * @memberof GeometryFactory
     */
    srid: number;
}
/**
 * 
 * @export
 * @interface Insurer
 */
export interface Insurer {
    /**
     * 
     * @type {number}
     * @memberof Insurer
     */
    id: number;
    /**
     * 
     * @type {Branch}
     * @memberof Insurer
     */
    branch?: Branch;
    /**
     * 
     * @type {string}
     * @memberof Insurer
     */
    insurerName: string;
}
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    id: number;
    /**
     * 
     * @type {Profile}
     * @memberof Invoice
     */
    owner?: Profile;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    profileId?: string;
    /**
     * 
     * @type {DocumentType}
     * @memberof Invoice
     */
    documentType?: DocumentType;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    sizeBytes: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    uploaded: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    storeId?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    uploadedTo?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    caseId?: number;
    /**
     * 
     * @type {Case}
     * @memberof Invoice
     */
    _case?: Case;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    branchId?: number;
    /**
     * 
     * @type {Branch}
     * @memberof Invoice
     */
    branch?: Branch;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    contact?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    cellphoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    vatNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    sent: boolean;
}
/**
 * 
 * @export
 * @interface InvoiceAllOf
 */
export interface InvoiceAllOf {
    /**
     * 
     * @type {string}
     * @memberof InvoiceAllOf
     */
    contact?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAllOf
     */
    cellphoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAllOf
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAllOf
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAllOf
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAllOf
     */
    vatNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceAllOf
     */
    sent: boolean;
}
/**
 * 
 * @export
 * @interface JsonDocument
 */
export interface JsonDocument {
    /**
     * 
     * @type {object}
     * @memberof JsonDocument
     */
    rootElement: object;
}
/**
 * 
 * @export
 * @interface KeyValue
 */
export interface KeyValue {
    /**
     * 
     * @type {string}
     * @memberof KeyValue
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof KeyValue
     */
    value: string;
}
/**
 * 
 * @export
 * @interface License
 */
export interface License {
    /**
     * 
     * @type {number}
     * @memberof License
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof License
     */
    appId?: number;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    licenseKey: string;
    /**
     * 
     * @type {Product}
     * @memberof License
     */
    owner?: Product;
    /**
     * 
     * @type {App}
     * @memberof License
     */
    consumedBy?: App;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    expires?: string;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    event?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Location
     */
    is_moving?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    timestamp?: string;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    odometer?: number;
    /**
     * 
     * @type {Coords}
     * @memberof Location
     */
    coords?: Coords;
    /**
     * 
     * @type {Activity}
     * @memberof Location
     */
    activity?: Activity;
    /**
     * 
     * @type {Battery}
     * @memberof Location
     */
    battery?: Battery;
    /**
     * 
     * @type {Extras}
     * @memberof Location
     */
    extras?: Extras;
}
/**
 * 
 * @export
 * @interface LocationDto
 */
export interface LocationDto {
    /**
     * 
     * @type {number}
     * @memberof LocationDto
     */
    latutide: number;
    /**
     * 
     * @type {number}
     * @memberof LocationDto
     */
    longitude: number;
    /**
     * 
     * @type {number}
     * @memberof LocationDto
     */
    caseId?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    ownerGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    created?: string;
}
/**
 * 
 * @export
 * @interface LocationNotificationDTO
 */
export interface LocationNotificationDTO {
    /**
     * 
     * @type {Location}
     * @memberof LocationNotificationDTO
     */
    location?: Location;
    /**
     * 
     * @type {string}
     * @memberof LocationNotificationDTO
     */
    user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationNotificationDTO
     */
    app_id?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationNotificationDTO
     */
    device_id?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationNotificationDTO
     */
    case_id?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationNotificationDTO
     */
    created: string;
}
/**
 * 
 * @export
 * @interface MaritalStatus
 */
export interface MaritalStatus {
    /**
     * 
     * @type {string}
     * @memberof MaritalStatus
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MaritalStatus
     */
    text: string;
    /**
     * 
     * @type {Status}
     * @memberof MaritalStatus
     */
    status: Status;
}
/**
 * 
 * @export
 * @interface MedicAlert
 */
export interface MedicAlert {
    /**
     * 
     * @type {number}
     * @memberof MedicAlert
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicAlert
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof MedicAlert
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicAlert
     */
    notes?: string;
}
/**
 * 
 * @export
 * @interface MedicAlertDTO
 */
export interface MedicAlertDTO {
    /**
     * 
     * @type {number}
     * @memberof MedicAlertDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicAlertDTO
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof MedicAlertDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicAlertDTO
     */
    notes?: string;
}
/**
 * 
 * @export
 * @interface MedicalInfo
 */
export interface MedicalInfo {
    /**
     * 
     * @type {number}
     * @memberof MedicalInfo
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfo
     */
    bloodType?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfo
     */
    height?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfo
     */
    weight?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfo
     */
    everHadHeartAttack?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfo
     */
    everHadStroke?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfo
     */
    biologicalGender?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfo
     */
    medicalAidProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfo
     */
    medicalAidNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfo
     */
    medicalAidPlan?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfo
     */
    dependentCount?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfo
     */
    principalMember?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfo
     */
    brokerName?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfo
     */
    brokerPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfo
     */
    brokerAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfo
     */
    allergies?: string;
    /**
     * 
     * @type {Array<Medication>}
     * @memberof MedicalInfo
     */
    medication?: Array<Medication>;
    /**
     * 
     * @type {Array<MedicAlert>}
     * @memberof MedicalInfo
     */
    medicalAlerts: Array<MedicAlert>;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfo
     */
    medicalDevices?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfo
     */
    organDonor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfo
     */
    dnr?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfo
     */
    allowBloodProducts?: boolean;
    /**
     * 
     * @type {Contact}
     * @memberof MedicalInfo
     */
    preferredMedicalPractitioner?: Contact;
    /**
     * 
     * @type {Contact}
     * @memberof MedicalInfo
     */
    preferredHospital?: Contact;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfo
     */
    medicalHistory?: string;
}
/**
 * 
 * @export
 * @interface MedicalInfoDTO
 */
export interface MedicalInfoDTO {
    /**
     * 
     * @type {number}
     * @memberof MedicalInfoDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    bloodType?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    height?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    weight?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfoDTO
     */
    everHadHeartAttack?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfoDTO
     */
    everHadStroke?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    biologicalGender?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    medicalAidProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    medicalAidNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    medicalAidPlan?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    dependentCount?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfoDTO
     */
    principalMember?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    brokerName?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    brokerPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    brokerAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    allergies?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    medicalDevices?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfoDTO
     */
    organDonor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfoDTO
     */
    dnr?: boolean;
    /**
     * 
     * @type {Array<MedicAlertDTO>}
     * @memberof MedicalInfoDTO
     */
    medicalAlerts: Array<MedicAlertDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfoDTO
     */
    allowBloodProducts?: boolean;
    /**
     * 
     * @type {ContactDTO}
     * @memberof MedicalInfoDTO
     */
    preferredMedicalPractitioner?: ContactDTO;
    /**
     * 
     * @type {ContactDTO}
     * @memberof MedicalInfoDTO
     */
    preferredHospital?: ContactDTO;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    medicalHistory?: string;
}
/**
 * 
 * @export
 * @interface Medication
 */
export interface Medication {
    /**
     * 
     * @type {number}
     * @memberof Medication
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Medication
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Medication
     */
    dose: string;
    /**
     * 
     * @type {string}
     * @memberof Medication
     */
    regularity: string;
    /**
     * 
     * @type {string}
     * @memberof Medication
     */
    usedFor: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum OS {
    Ios = 'IOS',
    Android = 'ANDROID'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum OS2 {
    Ios = 'IOS',
    Android = 'ANDROID'
}

/**
 * 
 * @export
 * @interface OccupationalSector
 */
export interface OccupationalSector {
    /**
     * 
     * @type {string}
     * @memberof OccupationalSector
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OccupationalSector
     */
    text: string;
    /**
     * 
     * @type {Status}
     * @memberof OccupationalSector
     */
    status: Status;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum OgcGeometryType {
    Point = 'Point',
    LineString = 'LineString',
    Polygon = 'Polygon',
    MultiPoint = 'MultiPoint',
    MultiLineString = 'MultiLineString',
    MultiPolygon = 'MultiPolygon',
    GeometryCollection = 'GeometryCollection',
    CircularString = 'CircularString',
    CompoundCurve = 'CompoundCurve',
    CurvePolygon = 'CurvePolygon',
    MultiCurve = 'MultiCurve',
    MultiSurface = 'MultiSurface',
    Curve = 'Curve',
    Surface = 'Surface',
    PolyhedralSurface = 'PolyhedralSurface',
    Tin = 'TIN'
}

/**
 * 
 * @export
 * @interface OperatorInfo
 */
export interface OperatorInfo {
    /**
     * 
     * @type {string}
     * @memberof OperatorInfo
     */
    profilePicUrl: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorInfo
     */
    operatorName: string;
}
/**
 * 
 * @export
 * @interface Option
 */
export interface Option {
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    text: string;
    /**
     * 
     * @type {Status}
     * @memberof Option
     */
    status: Status;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Ordinates {
    None = 'None',
    Spatial1 = 'Spatial1',
    
    Spatial2 = 'Spatial2',
    
    Xy = 'XY',
    Z = 'Z',
    
    Xyz = 'XYZ',
    Spatial4 = 'Spatial4',
    Spatial5 = 'Spatial5',
    Spatial6 = 'Spatial6',
    Spatial7 = 'Spatial7',
    Spatial8 = 'Spatial8',
    Spatial9 = 'Spatial9',
    Spatial10 = 'Spatial10',
    Spatial11 = 'Spatial11',
    Spatial12 = 'Spatial12',
    Spatial13 = 'Spatial13',
    Spatial14 = 'Spatial14',
    Spatial15 = 'Spatial15',
    Spatial16 = 'Spatial16',
    AllSpatialOrdinates = 'AllSpatialOrdinates',
    M = 'M',
    
    Xym = 'XYM',
    Xyzm = 'XYZM',
    Measure2 = 'Measure2',
    Measure3 = 'Measure3',
    Measure4 = 'Measure4',
    Measure5 = 'Measure5',
    Measure6 = 'Measure6',
    Measure7 = 'Measure7',
    Measure8 = 'Measure8',
    Measure9 = 'Measure9',
    Measure10 = 'Measure10',
    Measure11 = 'Measure11',
    Measure12 = 'Measure12',
    Measure13 = 'Measure13',
    Measure14 = 'Measure14',
    Measure15 = 'Measure15',
    Measure16 = 'Measure16',
    AllMeasureOrdinates = 'AllMeasureOrdinates',
    AllOrdinates = 'AllOrdinates'
}

/**
 * 
 * @export
 * @interface PanicDTO
 */
export interface PanicDTO {
    /**
     * 
     * @type {string}
     * @memberof PanicDTO
     */
    sourceIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof PanicDTO
     */
    pushToken?: string;
    /**
     * 
     * @type {string}
     * @memberof PanicDTO
     */
    profileGuid: string;
    /**
     * 
     * @type {string}
     * @memberof PanicDTO
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof PanicDTO
     */
    caseTypeId: number;
    /**
     * 
     * @type {string}
     * @memberof PanicDTO
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof PanicDTO
     */
    longitude?: string;
    /**
     * 
     * @type {string}
     * @memberof PanicDTO
     */
    radiusM?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PanicDTO
     */
    callRequest?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PanicDTO
     */
    agoraId?: number;
    /**
     * 
     * @type {string}
     * @memberof PanicDTO
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof PanicDTO
     */
    appId?: string;
}
/**
 * 
 * @export
 * @interface PartnerType
 */
export interface PartnerType {
    /**
     * 
     * @type {string}
     * @memberof PartnerType
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerType
     */
    text: string;
}
/**
 * 
 * @export
 * @interface PauseCaseDTO
 */
export interface PauseCaseDTO {
    /**
     * 
     * @type {number}
     * @memberof PauseCaseDTO
     */
    caseId: number;
    /**
     * 
     * @type {string}
     * @memberof PauseCaseDTO
     */
    until: string;
}
/**
 * 
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {GeometryFactory}
     * @memberof Point
     */
    factory?: GeometryFactory;
    /**
     * 
     * @type {object}
     * @memberof Point
     */
    userData?: object;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    srid: number;
    /**
     * 
     * @type {PrecisionModel}
     * @memberof Point
     */
    precisionModel?: PrecisionModel;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    numGeometries: number;
    /**
     * 
     * @type {boolean}
     * @memberof Point
     */
    isSimple: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Point
     */
    isValid: boolean;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    area: number;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    length: number;
    /**
     * 
     * @type {Point}
     * @memberof Point
     */
    centroid?: Point;
    /**
     * 
     * @type {Point}
     * @memberof Point
     */
    interiorPoint?: Point;
    /**
     * 
     * @type {Point}
     * @memberof Point
     */
    pointOnSurface?: Point;
    /**
     * 
     * @type {Geometry}
     * @memberof Point
     */
    envelope?: Geometry;
    /**
     * 
     * @type {Envelope}
     * @memberof Point
     */
    envelopeInternal?: Envelope;
    /**
     * 
     * @type {boolean}
     * @memberof Point
     */
    isRectangle: boolean;
    /**
     * 
     * @type {CoordinateSequence}
     * @memberof Point
     */
    coordinateSequence?: CoordinateSequence;
    /**
     * 
     * @type {Array<Coordinate>}
     * @memberof Point
     */
    coordinates?: Array<Coordinate>;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    numPoints: number;
    /**
     * 
     * @type {boolean}
     * @memberof Point
     */
    isEmpty: boolean;
    /**
     * 
     * @type {Dimension}
     * @memberof Point
     */
    dimension: Dimension;
    /**
     * 
     * @type {Dimension}
     * @memberof Point
     */
    boundaryDimension: Dimension;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    y: number;
    /**
     * 
     * @type {Coordinate}
     * @memberof Point
     */
    coordinate?: Coordinate;
    /**
     * 
     * @type {string}
     * @memberof Point
     */
    geometryType?: string;
    /**
     * 
     * @type {OgcGeometryType}
     * @memberof Point
     */
    ogcGeometryType: OgcGeometryType;
    /**
     * 
     * @type {Geometry}
     * @memberof Point
     */
    boundary?: Geometry;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    z: number;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    m: number;
}
/**
 * 
 * @export
 * @interface PointAllOf
 */
export interface PointAllOf {
    /**
     * 
     * @type {CoordinateSequence}
     * @memberof PointAllOf
     */
    coordinateSequence?: CoordinateSequence;
    /**
     * 
     * @type {Array<Coordinate>}
     * @memberof PointAllOf
     */
    coordinates?: Array<Coordinate>;
    /**
     * 
     * @type {number}
     * @memberof PointAllOf
     */
    numPoints: number;
    /**
     * 
     * @type {boolean}
     * @memberof PointAllOf
     */
    isEmpty: boolean;
    /**
     * 
     * @type {Dimension}
     * @memberof PointAllOf
     */
    dimension: Dimension;
    /**
     * 
     * @type {Dimension}
     * @memberof PointAllOf
     */
    boundaryDimension: Dimension;
    /**
     * 
     * @type {number}
     * @memberof PointAllOf
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof PointAllOf
     */
    y: number;
    /**
     * 
     * @type {Coordinate}
     * @memberof PointAllOf
     */
    coordinate?: Coordinate;
    /**
     * 
     * @type {string}
     * @memberof PointAllOf
     */
    geometryType?: string;
    /**
     * 
     * @type {OgcGeometryType}
     * @memberof PointAllOf
     */
    ogcGeometryType: OgcGeometryType;
    /**
     * 
     * @type {Geometry}
     * @memberof PointAllOf
     */
    boundary?: Geometry;
    /**
     * 
     * @type {number}
     * @memberof PointAllOf
     */
    z: number;
    /**
     * 
     * @type {number}
     * @memberof PointAllOf
     */
    m: number;
}
/**
 * 
 * @export
 * @interface Policy
 */
export interface Policy {
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    version: string;
    /**
     * 
     * @type {boolean}
     * @memberof Policy
     */
    requiresBiometric?: boolean;
    /**
     * 
     * @type {Array<Api>}
     * @memberof Policy
     */
    apis: Array<Api>;
}
/**
 * 
 * @export
 * @interface PrecisionModel
 */
export interface PrecisionModel {
    /**
     * 
     * @type {boolean}
     * @memberof PrecisionModel
     */
    isFloating: boolean;
    /**
     * 
     * @type {number}
     * @memberof PrecisionModel
     */
    maximumSignificantDigits: number;
    /**
     * 
     * @type {number}
     * @memberof PrecisionModel
     */
    scale: number;
    /**
     * 
     * @type {PrecisionModels}
     * @memberof PrecisionModel
     */
    precisionModelType: PrecisionModels;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PrecisionModels {
    Floating = 'Floating',
    FloatingSingle = 'FloatingSingle',
    Fixed = 'Fixed'
}

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    profileId: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sponsorId?: string;
    /**
     * 
     * @type {Profile}
     * @memberof Product
     */
    profile: Profile;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    activationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productStatusId: string;
    /**
     * 
     * @type {ProductStatus}
     * @memberof Product
     */
    productStatus?: ProductStatus;
    /**
     * 
     * @type {ProductOffering}
     * @memberof Product
     */
    productOffering: ProductOffering;
    /**
     * 
     * @type {Array<License>}
     * @memberof Product
     */
    licenses?: Array<License>;
    /**
     * 
     * @type {Profile}
     * @memberof Product
     */
    sponsor?: Profile;
}
/**
 * 
 * @export
 * @interface ProductDTO
 */
export interface ProductDTO {
    /**
     * 
     * @type {number}
     * @memberof ProductDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDTO
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    formattedprice: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDTO
     */
    vat: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    image?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDTO
     */
    isPaid?: boolean;
}
/**
 * 
 * @export
 * @interface ProductOffering
 */
export interface ProductOffering {
    /**
     * 
     * @type {number}
     * @memberof ProductOffering
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductOffering
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ProductOffering
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductOffering
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ProductOffering
     */
    priceExVat: number;
    /**
     * 
     * @type {number}
     * @memberof ProductOffering
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ProductOffering
     */
    glCode: string;
    /**
     * 
     * @type {number}
     * @memberof ProductOffering
     */
    vat: number;
    /**
     * 
     * @type {string}
     * @memberof ProductOffering
     */
    imageURL?: string;
    /**
     * 
     * @type {Status}
     * @memberof ProductOffering
     */
    status?: Status;
    /**
     * 
     * @type {Terms}
     * @memberof ProductOffering
     */
    productTerms?: Terms;
    /**
     * 
     * @type {string}
     * @memberof ProductOffering
     */
    associatedApp?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductOffering
     */
    isPaid?: boolean;
}
/**
 * 
 * @export
 * @interface ProductStatus
 */
export interface ProductStatus {
    /**
     * 
     * @type {string}
     * @memberof ProductStatus
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductStatus
     */
    text: string;
    /**
     * 
     * @type {Status}
     * @memberof ProductStatus
     */
    status: Status;
}
/**
 * 
 * @export
 * @interface ProductType
 */
export interface ProductType {
    /**
     * 
     * @type {string}
     * @memberof ProductType
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductType
     */
    text: string;
    /**
     * 
     * @type {Array<CompanyProductType>}
     * @memberof ProductType
     */
    companyProductTypes: Array<CompanyProductType>;
}
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    tbxPassportId?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    connectMeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    lastUpdated: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    firstNames: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    genderIdentity?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    mainLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    homeBase?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    bio?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    trialUser: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    sponsored: boolean;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    trialStart?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    passportNumber?: string;
    /**
     * 
     * @type {OccupationalSector}
     * @memberof Profile
     */
    occupationalSector?: OccupationalSector;
    /**
     * 
     * @type {MaritalStatus}
     * @memberof Profile
     */
    maritalStatus?: MaritalStatus;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    numberOfChildDependents?: number;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    numberOfAdultDependents?: number;
    /**
     * 
     * @type {Document}
     * @memberof Profile
     */
    profilePhoto?: Document;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    distinctiveFeatures?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    glasses?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    contactLenses?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    falseTeeth?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    hearingAids?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    tattoos?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    piercings?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    heightAndBuild?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    hairColour?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    eyeColour?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    race?: string;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof Profile
     */
    contacts: Array<Contact>;
    /**
     * 
     * @type {Array<ProfileContact>}
     * @memberof Profile
     */
    profileContacts: Array<ProfileContact>;
    /**
     * 
     * @type {Contact}
     * @memberof Profile
     */
    self?: Contact;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Profile
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {Array<Device>}
     * @memberof Profile
     */
    devices?: Array<Device>;
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof Profile
     */
    invoices?: Array<Invoice>;
    /**
     * 
     * @type {MedicalInfo}
     * @memberof Profile
     */
    medicalInfo?: MedicalInfo;
    /**
     * 
     * @type {Array<Account>}
     * @memberof Profile
     */
    account?: Array<Account>;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Profile
     */
    products?: Array<Product>;
    /**
     * 
     * @type {Array<TrackboxSubscription>}
     * @memberof Profile
     */
    subscriptions?: Array<TrackboxSubscription>;
    /**
     * 
     * @type {ContactPreference}
     * @memberof Profile
     */
    contactPreference?: ContactPreference;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    alertRadiusKm?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    subscribed?: boolean;
    /**
     * 
     * @type {Branch}
     * @memberof Profile
     */
    currentBranch?: Branch;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    telegramId?: string;
    /**
     * 
     * @type {Array<Reminder>}
     * @memberof Profile
     */
    reminders?: Array<Reminder>;
    /**
     * 
     * @type {Array<Ticket>}
     * @memberof Profile
     */
    tickets?: Array<Ticket>;
    /**
     * 
     * @type {Array<Case>}
     * @memberof Profile
     */
    cases?: Array<Case>;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof Profile
     */
    assets: Array<Asset>;
    /**
     * 
     * @type {Array<Case>}
     * @memberof Profile
     */
    assignedCases?: Array<Case>;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    otp?: string;
    /**
     * 
     * @type {States}
     * @memberof Profile
     */
    userStatus: States;
    /**
     * 
     * @type {Policy}
     * @memberof Profile
     */
    permissions?: Policy;
    /**
     * 
     * @type {Cart}
     * @memberof Profile
     */
    cart?: Cart;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    isStaff?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    isOperative?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    revenueCatId?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    secretShareCode?: string;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof Profile
     */
    getEmergencyContacts: Array<Contact>;
    /**
     * 
     * @type {Contact}
     * @memberof Profile
     */
    getSelfContact?: Contact;
    /**
     * 
     * @type {Address}
     * @memberof Profile
     */
    getMainAddress?: Address;
}
/**
 * 
 * @export
 * @interface ProfileContact
 */
export interface ProfileContact {
    /**
     * 
     * @type {ContactType}
     * @memberof ProfileContact
     */
    contactType: ContactType;
    /**
     * 
     * @type {string}
     * @memberof ProfileContact
     */
    profileGuid: string;
    /**
     * 
     * @type {Profile}
     * @memberof ProfileContact
     */
    profile: Profile;
    /**
     * 
     * @type {number}
     * @memberof ProfileContact
     */
    contactId: number;
    /**
     * 
     * @type {Contact}
     * @memberof ProfileContact
     */
    contact: Contact;
    /**
     * 
     * @type {string}
     * @memberof ProfileContact
     */
    alias?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileContact
     */
    confirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileContact
     */
    confirmationPending?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileContact
     */
    optOutKey?: string;
}
/**
 * 
 * @export
 * @interface ProfileDTO
 */
export interface ProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    tbxPassportId: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    connectMeCode: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    lastUpdated?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    firstNames: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    genderIdentity?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    mainLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    homeBase?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    trialUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    sponsored?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    passportNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    occupationalSector?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    maritalStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfileDTO
     */
    numberOfChildDependents?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileDTO
     */
    numberOfAdultDependents?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    mainCell: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    profilePhoto?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    distinctiveFeatures?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    glasses?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    contactLenses?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    falseTeeth?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    hearingAids?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    tattoos?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    piercings?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    heightAndBuild?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    hairColour?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    eyeColour?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    race?: string;
    /**
     * 
     * @type {ErpcDTO}
     * @memberof ProfileDTO
     */
    company?: ErpcDTO;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    telegramId?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfileDTO
     */
    userStatus?: number;
    /**
     * 
     * @type {Array<ContactDTO>}
     * @memberof ProfileDTO
     */
    contacts?: Array<ContactDTO>;
    /**
     * 
     * @type {MedicalInfoDTO}
     * @memberof ProfileDTO
     */
    medicalInfo?: MedicalInfoDTO;
}
/**
 * 
 * @export
 * @interface Reminder
 */
export interface Reminder {
    /**
     * 
     * @type {number}
     * @memberof Reminder
     */
    id: number;
    /**
     * 
     * @type {Profile}
     * @memberof Reminder
     */
    owner: Profile;
    /**
     * 
     * @type {string}
     * @memberof Reminder
     */
    profileId: string;
    /**
     * 
     * @type {string}
     * @memberof Reminder
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Reminder
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof Reminder
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Reminder
     */
    savedTo?: string;
    /**
     * 
     * @type {string}
     * @memberof Reminder
     */
    cat?: string;
    /**
     * 
     * @type {string}
     * @memberof Reminder
     */
    trigger?: string;
    /**
     * 
     * @type {string}
     * @memberof Reminder
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof Reminder
     */
    updated: string;
    /**
     * 
     * @type {boolean}
     * @memberof Reminder
     */
    repeat?: boolean;
}
/**
 * 
 * @export
 * @interface RevenueCatResponse
 */
export interface RevenueCatResponse {
    /**
     * 
     * @type {string}
     * @memberof RevenueCatResponse
     */
    requestDate: string;
    /**
     * 
     * @type {number}
     * @memberof RevenueCatResponse
     */
    requestDateMs: number;
    /**
     * 
     * @type {Subscriber}
     * @memberof RevenueCatResponse
     */
    subscriber?: Subscriber;
}
/**
 * 
 * @export
 * @interface Sector
 */
export interface Sector {
    /**
     * 
     * @type {string}
     * @memberof Sector
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Sector
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Sector
     */
    companyId: number;
    /**
     * 
     * @type {Company}
     * @memberof Sector
     */
    company: Company;
}
/**
 * 
 * @export
 * @interface SectorDTO
 */
export interface SectorDTO {
    /**
     * 
     * @type {string}
     * @memberof SectorDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SectorDTO
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SectorDTO
     */
    companyId: number;
}
/**
 * 
 * @export
 * @interface ServerResponse
 */
export interface ServerResponse {
    /**
     * 
     * @type {string}
     * @memberof ServerResponse
     */
    fileListMode: string;
    /**
     * 
     * @type {Array<FileDetail>}
     * @memberof ServerResponse
     */
    fileList: Array<FileDetail>;
    /**
     * 
     * @type {string}
     * @memberof ServerResponse
     */
    uploadingStatus: string;
}
/**
 * 
 * @export
 * @interface ShortUserDTO
 */
export interface ShortUserDTO {
    /**
     * 
     * @type {string}
     * @memberof ShortUserDTO
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof ShortUserDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShortUserDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ShortUserDTO
     */
    cell: string;
    /**
     * 
     * @type {string}
     * @memberof ShortUserDTO
     */
    state: string;
}
/**
 * 
 * @export
 * @interface StartRecordResponse
 */
export interface StartRecordResponse {
    /**
     * 
     * @type {string}
     * @memberof StartRecordResponse
     */
    cname: string;
    /**
     * 
     * @type {string}
     * @memberof StartRecordResponse
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof StartRecordResponse
     */
    resourceId: string;
    /**
     * 
     * @type {string}
     * @memberof StartRecordResponse
     */
    sid: string;
}
/**
 * 
 * @export
 * @interface State
 */
export interface State {
    /**
     * 
     * @type {string}
     * @memberof State
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof State
     */
    fullName: string;
}
/**
 * 
 * @export
 * @interface Statement
 */
export interface Statement {
    /**
     * 
     * @type {string}
     * @memberof Statement
     */
    controller: string;
    /**
     * 
     * @type {string}
     * @memberof Statement
     */
    action: string;
    /**
     * 
     * @type {string}
     * @memberof Statement
     */
    allow: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum States {
    Created = 'CREATED',
    Active = 'ACTIVE',
    OtpSent = 'OTP_SENT',
    OtpSuccess = 'OTP_SUCCESS',
    Disabled = 'DISABLED'
}

/**
 * 
 * @export
 * @interface Status
 */
export interface Status {
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    description: string;
}
/**
 * 
 * @export
 * @interface StopResponse
 */
export interface StopResponse {
    /**
     * 
     * @type {string}
     * @memberof StopResponse
     */
    cname: string;
    /**
     * 
     * @type {string}
     * @memberof StopResponse
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof StopResponse
     */
    resourceId: string;
    /**
     * 
     * @type {string}
     * @memberof StopResponse
     */
    sid: string;
    /**
     * 
     * @type {ServerResponse}
     * @memberof StopResponse
     */
    serverResponse: ServerResponse;
}
/**
 * 
 * @export
 * @interface SubOptions
 */
export interface SubOptions {
    /**
     * 
     * @type {string}
     * @memberof SubOptions
     */
    profileGuid: string;
    /**
     * 
     * @type {string}
     * @memberof SubOptions
     */
    sponsorGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SubOptions
     */
    revenueCatIdentifier?: string;
    /**
     * 
     * @type {number}
     * @memberof SubOptions
     */
    tbxSubId: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubOptions
     */
    add: boolean;
}
/**
 * 
 * @export
 * @interface Subscriber
 */
export interface Subscriber {
    /**
     * 
     * @type {{ [key: string]: Entitlement; }}
     * @memberof Subscriber
     */
    entitlements: { [key: string]: Entitlement; };
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    firstSeen: string;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    lastSeen: string;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    managementUrl: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Subscriber
     */
    nonSubscriptions: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    originalAppUserId: string;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    originalApplicationVersion: string;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    originalPurchaseDate?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Subscriber
     */
    otherPurchases: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: Subscription; }}
     * @memberof Subscriber
     */
    subscriptions: { [key: string]: Subscription; };
}
/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    autoResumeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    billingIssuesDetectedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    expiresDate: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    gracePeriodExpiresDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Subscription
     */
    isSandbox: boolean;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    originalPurchaseDate: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    periodType: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    productPlanIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    purchaseDate: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    refundedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    store: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    storeTransactionId: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    unsubscribeDetectedAt?: string;
}
/**
 * 
 * @export
 * @interface SubscriptionOption
 */
export interface SubscriptionOption {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionOption
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionOption
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionOption
     */
    revenueCatId: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionOption
     */
    centsCost: number;
}
/**
 * 
 * @export
 * @interface SuggestionDTO
 */
export interface SuggestionDTO {
    /**
     * 
     * @type {string}
     * @memberof SuggestionDTO
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof SuggestionDTO
     */
    suggestion: string;
    /**
     * 
     * @type {string}
     * @memberof SuggestionDTO
     */
    cell?: string;
    /**
     * 
     * @type {string}
     * @memberof SuggestionDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SuggestionDTO
     */
    dob?: string;
    /**
     * 
     * @type {string}
     * @memberof SuggestionDTO
     */
    erpc?: string;
    /**
     * 
     * @type {string}
     * @memberof SuggestionDTO
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface TbxSubDto
 */
export interface TbxSubDto {
    /**
     * 
     * @type {number}
     * @memberof TbxSubDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TbxSubDto
     */
    profileId: string;
    /**
     * 
     * @type {string}
     * @memberof TbxSubDto
     */
    sponsorId?: string;
    /**
     * 
     * @type {number}
     * @memberof TbxSubDto
     */
    subscriptionOptionId: number;
    /**
     * 
     * @type {string}
     * @memberof TbxSubDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TbxSubDto
     */
    revenueCatId: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Terms {
    Monthly = 'Monthly',
    Weekly = 'Weekly',
    Daily = 'Daily'
}

/**
 * 
 * @export
 * @interface Ticket
 */
export interface Ticket {
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    ownerId?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    officerId?: string;
    /**
     * 
     * @type {Profile}
     * @memberof Ticket
     */
    caseOfficer?: Profile;
    /**
     * 
     * @type {Profile}
     * @memberof Ticket
     */
    caseComplainant?: Profile;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    agoraFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    reported?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    opened?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    closed?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    reminder?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    caseNumber: string;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    caseTypeId?: number;
    /**
     * 
     * @type {CaseType}
     * @memberof Ticket
     */
    caseType?: CaseType;
    /**
     * 
     * @type {CaseStatus}
     * @memberof Ticket
     */
    status: CaseStatus;
    /**
     * 
     * @type {Point}
     * @memberof Ticket
     */
    location?: Point;
    /**
     * 
     * @type {Address}
     * @memberof Ticket
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    sapsCaseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    sapsStationIssuingCase?: string;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    originalBranchId?: number;
    /**
     * 
     * @type {Branch}
     * @memberof Ticket
     */
    originalBranch?: Branch;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    currentBranchId?: number;
    /**
     * 
     * @type {Branch}
     * @memberof Ticket
     */
    currentBranch?: Branch;
    /**
     * 
     * @type {boolean}
     * @memberof Ticket
     */
    callRequest?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    agoraId?: number;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    pushToken?: string;
    /**
     * 
     * @type {JsonDocument}
     * @memberof Ticket
     */
    caseData?: JsonDocument;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Ticket
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {Ticket}
     * @memberof Ticket
     */
    originalTicket?: Ticket;
    /**
     * 
     * @type {Array<Ticket>}
     * @memberof Ticket
     */
    replies?: Array<Ticket>;
}
/**
 * 
 * @export
 * @interface TicketAllOf
 */
export interface TicketAllOf {
    /**
     * 
     * @type {Ticket}
     * @memberof TicketAllOf
     */
    originalTicket?: Ticket;
    /**
     * 
     * @type {Array<Ticket>}
     * @memberof TicketAllOf
     */
    replies?: Array<Ticket>;
}
/**
 * 
 * @export
 * @interface TrackboxSubscription
 */
export interface TrackboxSubscription {
    /**
     * 
     * @type {number}
     * @memberof TrackboxSubscription
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TrackboxSubscription
     */
    profileId: string;
    /**
     * 
     * @type {string}
     * @memberof TrackboxSubscription
     */
    sponsorId?: string;
    /**
     * 
     * @type {Profile}
     * @memberof TrackboxSubscription
     */
    profile: Profile;
    /**
     * 
     * @type {Profile}
     * @memberof TrackboxSubscription
     */
    sponsor?: Profile;
    /**
     * 
     * @type {number}
     * @memberof TrackboxSubscription
     */
    subscriptionOptionId: number;
    /**
     * 
     * @type {SubscriptionOption}
     * @memberof TrackboxSubscription
     */
    subscriptionOption: SubscriptionOption;
}
/**
 * 
 * @export
 * @interface UpdateBranchDTO
 */
export interface UpdateBranchDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateBranchDTO
     */
    uid: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBranchDTO
     */
    branchId: number;
}
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    guid?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    cell?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    firstNames?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    base64Permissions?: string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddAddressToGuid: async (address: AddressDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'address' is not null or undefined
            assertParamExists('adminAddAddressToGuid', 'address', address)
            const localVarPath = `/Admin/AddAddressToGuid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddContact: async (contact: ContactDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contact' is not null or undefined
            assertParamExists('adminAddContact', 'contact', contact)
            const localVarPath = `/Admin/AddContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactRelationship} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddContactRelation: async (contact: ContactRelationship, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contact' is not null or undefined
            assertParamExists('adminAddContactRelation', 'contact', contact)
            const localVarPath = `/Admin/AddContactRelationToGuid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBranches: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('adminGetBranches', 'companyId', companyId)
            const localVarPath = `/Admin/GetBranches/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCompanies: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/GetCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContacts: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('adminGetContacts', 'guid', guid)
            const localVarPath = `/Admin/GetContacts/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetDevicesForGuid: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('adminGetDevicesForGuid', 'guid', guid)
            const localVarPath = `/Admin/GetDevicesForGuid/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetFileLinkByKey: async (guid: string, key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('adminGetFileLinkByKey', 'guid', guid)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('adminGetFileLinkByKey', 'key', key)
            const localVarPath = `/Admin/GetFileByKey/{guid}/{key}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProfile: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('adminGetProfile', 'guid', guid)
            const localVarPath = `/Admin/MyProfile/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsForGuid: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('adminProductsForGuid', 'guid', guid)
            const localVarPath = `/Admin/ProductsForGuid/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateContact: async (contact: ContactDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contact' is not null or undefined
            assertParamExists('adminUpdateContact', 'contact', contact)
            const localVarPath = `/Admin/UpdateContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBranchDTO} newBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateERPCForUID: async (newBranch: UpdateBranchDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newBranch' is not null or undefined
            assertParamExists('adminUpdateERPCForUID', 'newBranch', newBranch)
            const localVarPath = `/Admin/UpdateERPCForUID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newBranch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProfileDTO} profile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateProfile: async (profile: ProfileDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profile' is not null or undefined
            assertParamExists('adminUpdateProfile', 'profile', profile)
            const localVarPath = `/Admin/UpdateProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAddAddressToGuid(address: AddressDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAddAddressToGuid(address, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAddContact(contact: ContactDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAddContact(contact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactRelationship} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAddContactRelation(contact: ContactRelationship, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAddContactRelation(contact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetBranches(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ErpcDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetBranches(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCompanies(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetContacts(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetContacts(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetDevicesForGuid(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetDevicesForGuid(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetFileLinkByKey(guid: string, key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetFileLinkByKey(guid, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetProfile(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetProfile(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductsForGuid(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductsForGuid(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateContact(contact: ContactDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateContact(contact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBranchDTO} newBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateERPCForUID(newBranch: UpdateBranchDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateERPCForUID(newBranch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProfileDTO} profile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateProfile(profile: ProfileDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateProfile(profile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddAddressToGuid(address: AddressDTO, options?: any): AxiosPromise<ProfileDTO> {
            return localVarFp.adminAddAddressToGuid(address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddContact(contact: ContactDTO, options?: any): AxiosPromise<string> {
            return localVarFp.adminAddContact(contact, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactRelationship} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddContactRelation(contact: ContactRelationship, options?: any): AxiosPromise<ContactDTO> {
            return localVarFp.adminAddContactRelation(contact, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBranches(companyId: number, options?: any): AxiosPromise<Array<ErpcDTO>> {
            return localVarFp.adminGetBranches(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCompanies(options?: any): AxiosPromise<Array<CompanyDTO>> {
            return localVarFp.adminGetCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContacts(guid: string, options?: any): AxiosPromise<Array<ContactDTO>> {
            return localVarFp.adminGetContacts(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetDevicesForGuid(guid: string, options?: any): AxiosPromise<Array<DeviceDTO>> {
            return localVarFp.adminGetDevicesForGuid(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetFileLinkByKey(guid: string, key: string, options?: any): AxiosPromise<any> {
            return localVarFp.adminGetFileLinkByKey(guid, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProfile(guid: string, options?: any): AxiosPromise<ProfileDTO> {
            return localVarFp.adminGetProfile(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsForGuid(guid: string, options?: any): AxiosPromise<Array<ProductDTO>> {
            return localVarFp.adminProductsForGuid(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateContact(contact: ContactDTO, options?: any): AxiosPromise<ContactDTO> {
            return localVarFp.adminUpdateContact(contact, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBranchDTO} newBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateERPCForUID(newBranch: UpdateBranchDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminUpdateERPCForUID(newBranch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileDTO} profile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateProfile(profile: ProfileDTO, options?: any): AxiosPromise<ProfileDTO> {
            return localVarFp.adminUpdateProfile(profile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @param {AddressDTO} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAddAddressToGuid(address: AddressDTO, options?: any) {
        return AdminApiFp(this.configuration).adminAddAddressToGuid(address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactDTO} contact 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAddContact(contact: ContactDTO, options?: any) {
        return AdminApiFp(this.configuration).adminAddContact(contact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactRelationship} contact 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAddContactRelation(contact: ContactRelationship, options?: any) {
        return AdminApiFp(this.configuration).adminAddContactRelation(contact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetBranches(companyId: number, options?: any) {
        return AdminApiFp(this.configuration).adminGetBranches(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCompanies(options?: any) {
        return AdminApiFp(this.configuration).adminGetCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetContacts(guid: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetContacts(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetDevicesForGuid(guid: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetDevicesForGuid(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetFileLinkByKey(guid: string, key: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetFileLinkByKey(guid, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetProfile(guid: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetProfile(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminProductsForGuid(guid: string, options?: any) {
        return AdminApiFp(this.configuration).adminProductsForGuid(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactDTO} contact 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateContact(contact: ContactDTO, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateContact(contact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateBranchDTO} newBranch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateERPCForUID(newBranch: UpdateBranchDTO, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateERPCForUID(newBranch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileDTO} profile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateProfile(profile: ProfileDTO, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateProfile(profile, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CaseApi - axios parameter creator
 * @export
 */
export const CaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAddAddress: async (address: AddressDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'address' is not null or undefined
            assertParamExists('caseAddAddress', 'address', address)
            const localVarPath = `/Case/AddAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} branchId 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAddAddressToBranch: async (branchId: number, address: AddressDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('caseAddAddressToBranch', 'branchId', branchId)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('caseAddAddressToBranch', 'address', address)
            const localVarPath = `/Case/AddAddressToBranch/{branchId}`
                .replace(`{${"branchId"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseid 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAddAddressToCase: async (caseid: number, address: AddressDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseid' is not null or undefined
            assertParamExists('caseAddAddressToCase', 'caseid', caseid)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('caseAddAddressToCase', 'address', address)
            const localVarPath = `/Case/AddAddressToCase/{caseid}`
                .replace(`{${"caseid"}}`, encodeURIComponent(String(caseid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAddAddressToGuid: async (address: AddressDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'address' is not null or undefined
            assertParamExists('caseAddAddressToGuid', 'address', address)
            const localVarPath = `/Case/AddAddressToGuid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAddContact: async (contact: ContactDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contact' is not null or undefined
            assertParamExists('caseAddContact', 'contact', contact)
            const localVarPath = `/Case/AddContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactRelationship} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAddContactRelation: async (contact: ContactRelationship, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contact' is not null or undefined
            assertParamExists('caseAddContactRelation', 'contact', contact)
            const localVarPath = `/Case/AddContactRelationToGuid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<object>} [formdata] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAddErpcMedia: async (formdata?: Array<object>, file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/AddErpcMedia`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (formdata) {
                localVarQueryParameter['formdata'] = formdata;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkStateDTO} stateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseBulkUpdateCaseStates: async (stateDto: BulkStateDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stateDto' is not null or undefined
            assertParamExists('caseBulkUpdateCaseStates', 'stateDto', stateDto)
            const localVarPath = `/Case/BulkUpdateCaseStates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkStateDTO} stateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseBulkUpdateErpcStates: async (stateDto: BulkStateDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stateDto' is not null or undefined
            assertParamExists('caseBulkUpdateErpcStates', 'stateDto', stateDto)
            const localVarPath = `/Case/BulkUpdateErpcStates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkUserStateDTO} stateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseBulkUpdateUserStates: async (stateDto: BulkUserStateDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stateDto' is not null or undefined
            assertParamExists('caseBulkUpdateUserStates', 'stateDto', stateDto)
            const localVarPath = `/Case/BulkUpdateUserStates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CallConnectDTO} callConnectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseCallConnect: async (callConnectDto: CallConnectDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'callConnectDto' is not null or undefined
            assertParamExists('caseCallConnect', 'callConnectDto', callConnectDto)
            const localVarPath = `/Case/CallConnect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(callConnectDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseCancelCase: async (caseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('caseCancelCase', 'caseId', caseId)
            const localVarPath = `/Case/CancelCase/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CaseDTO} caseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseCreateCase: async (caseDto: CaseDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseDto' is not null or undefined
            assertParamExists('caseCreateCase', 'caseDto', caseDto)
            const localVarPath = `/Case/CreateCase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetBranch: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('caseGetBranch', 'id', id)
            const localVarPath = `/Case/GetBranch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} branchId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetBranchFileLinkById: async (branchId: number, fileId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('caseGetBranchFileLinkById', 'branchId', branchId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('caseGetBranchFileLinkById', 'fileId', fileId)
            const localVarPath = `/Case/GetBranchFileLink/{branchId}/{fileId}`
                .replace(`{${"branchId"}}`, encodeURIComponent(String(branchId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetBranches: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/GetBranches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetBranchesForCompany: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('caseGetBranchesForCompany', 'companyId', companyId)
            const localVarPath = `/Case/GetBranchesForCompany/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CaseBookFilterDTO} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCaseBookCases: async (filter: CaseBookFilterDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('caseGetCaseBookCases', 'filter', filter)
            const localVarPath = `/Case/GetCaseBookCases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCaseById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('caseGetCaseById', 'id', id)
            const localVarPath = `/Case/GetCaseById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCaseCategoryOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/GetCaseCategoryOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCases: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/GetCases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCities: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/GetCities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCompanies: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/GetCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCompany: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('caseGetCompany', 'id', id)
            const localVarPath = `/Case/GetCompany/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetConnectionString: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/GetConnectionString`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetContacts: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('caseGetContacts', 'guid', guid)
            const localVarPath = `/Case/GetContacts/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetDevicesForGuid: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('caseGetDevicesForGuid', 'guid', guid)
            const localVarPath = `/Case/GetDevicesForGuid/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetErpcMembers: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('caseGetErpcMembers', 'id', id)
            const localVarPath = `/Case/GetErpcMembers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetErpcOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/GetERPCOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetFileLinkById: async (caseId: number, fileId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('caseGetFileLinkById', 'caseId', caseId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('caseGetFileLinkById', 'fileId', fileId)
            const localVarPath = `/Case/GetFileLink/{caseId}/{fileId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetFileLinkByKey: async (guid: string, key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('caseGetFileLinkByKey', 'guid', guid)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('caseGetFileLinkByKey', 'key', key)
            const localVarPath = `/Case/GetFileByKey/{guid}/{key}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CaseFilterDTO} caseFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetFilteredCases: async (caseFilterDto: CaseFilterDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseFilterDto' is not null or undefined
            assertParamExists('caseGetFilteredCases', 'caseFilterDto', caseFilterDto)
            const localVarPath = `/Case/GetFilteredCases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetLocationsForCase: async (caseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('caseGetLocationsForCase', 'caseId', caseId)
            const localVarPath = `/Case/GetLocationsForCase/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} branchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetMembersForBranch: async (branchId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('caseGetMembersForBranch', 'branchId', branchId)
            const localVarPath = `/Case/GetMembersForBranch/{branchId}`
                .replace(`{${"branchId"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetOperatorPicForCase: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('caseGetOperatorPicForCase', 'id', id)
            const localVarPath = `/Case/GetOperatorPicForCase/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetProfile: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('caseGetProfile', 'guid', guid)
            const localVarPath = `/Case/MyProfile/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetRecording: async (prefix: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prefix' is not null or undefined
            assertParamExists('caseGetRecording', 'prefix', prefix)
            const localVarPath = `/Case/GetRecording/{prefix}`
                .replace(`{${"prefix"}}`, encodeURIComponent(String(prefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetRevenueCatSubscriptions: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('caseGetRevenueCatSubscriptions', 'guid', guid)
            const localVarPath = `/Case/GetRevenueCatSubscriptionsForUser/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetSectorsForCompany: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('caseGetSectorsForCompany', 'companyId', companyId)
            const localVarPath = `/Case/GetSectorsForCompany/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetTbxCatSubOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/GetTbxSubOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetTbxSubscriptionsForUser: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('caseGetTbxSubscriptionsForUser', 'guid', guid)
            const localVarPath = `/Case/GetTbxSubscriptionsForUser/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseListBranchFiles: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('caseListBranchFiles', 'id', id)
            const localVarPath = `/Case/ListBranchFiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseListCaseFiles: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('caseListCaseFiles', 'id', id)
            const localVarPath = `/Case/ListCaseFiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LocationNotificationDTO} location 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseLocationUpdate: async (location: LocationNotificationDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'location' is not null or undefined
            assertParamExists('caseLocationUpdate', 'location', location)
            const localVarPath = `/Case/LocationUpdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(location, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<object>} [formdata] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseNewDocument: async (formdata?: Array<object>, file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/NewDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (formdata) {
                localVarQueryParameter['formdata'] = formdata;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<SubOptions>} subOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseOnPurchase: async (subOptions: Array<SubOptions>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subOptions' is not null or undefined
            assertParamExists('caseOnPurchase', 'subOptions', subOptions)
            const localVarPath = `/Case/UpdateSubs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PanicDTO} panicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casePanic: async (panicDto: PanicDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'panicDto' is not null or undefined
            assertParamExists('casePanic', 'panicDto', panicDto)
            const localVarPath = `/Case/Panic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(panicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PauseCaseDTO} pauseCaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casePauseCase: async (pauseCaseDto: PauseCaseDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pauseCaseDto' is not null or undefined
            assertParamExists('casePauseCase', 'pauseCaseDto', pauseCaseDto)
            const localVarPath = `/Case/PauseCase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pauseCaseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseProductsForGuid: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('caseProductsForGuid', 'guid', guid)
            const localVarPath = `/Case/ProductsForGuid/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} casetypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseSaveCaseCategory: async (caseId: number, casetypeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('caseSaveCaseCategory', 'caseId', caseId)
            // verify required parameter 'casetypeId' is not null or undefined
            assertParamExists('caseSaveCaseCategory', 'casetypeId', casetypeId)
            const localVarPath = `/Case/SaveCaseCategory/{caseId}/{casetypeId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"casetypeId"}}`, encodeURIComponent(String(casetypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {JsonDocument} caseData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseSaveCaseData: async (id: number, caseData: JsonDocument, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('caseSaveCaseData', 'id', id)
            // verify required parameter 'caseData' is not null or undefined
            assertParamExists('caseSaveCaseData', 'caseData', caseData)
            const localVarPath = `/Case/SaveCaseData/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseSearch: async (searchStr: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchStr' is not null or undefined
            assertParamExists('caseSearch', 'searchStr', searchStr)
            const localVarPath = `/Case/search/{searchStr}`
                .replace(`{${"searchStr"}}`, encodeURIComponent(String(searchStr)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseSearchPredict: async (partial: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partial' is not null or undefined
            assertParamExists('caseSearchPredict', 'partial', partial)
            const localVarPath = `/Case/SearchPredict/{partial}`
                .replace(`{${"partial"}}`, encodeURIComponent(String(partial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseSetAsSponsored: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('caseSetAsSponsored', 'guid', guid)
            const localVarPath = `/Case/SetSponsored/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} channelName 
         * @param {string} uid 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseStartRecording: async (channelName: string, uid: string, caseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelName' is not null or undefined
            assertParamExists('caseStartRecording', 'channelName', channelName)
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('caseStartRecording', 'uid', uid)
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('caseStartRecording', 'caseId', caseId)
            const localVarPath = `/Case/StartRecording/{channelName}/{uid}/{caseId}`
                .replace(`{${"channelName"}}`, encodeURIComponent(String(channelName)))
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} channelName 
         * @param {string} uid 
         * @param {string} sid 
         * @param {string} resourceId 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseStopRecording: async (channelName: string, uid: string, sid: string, resourceId: string, caseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelName' is not null or undefined
            assertParamExists('caseStopRecording', 'channelName', channelName)
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('caseStopRecording', 'uid', uid)
            // verify required parameter 'sid' is not null or undefined
            assertParamExists('caseStopRecording', 'sid', sid)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('caseStopRecording', 'resourceId', resourceId)
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('caseStopRecording', 'caseId', caseId)
            const localVarPath = `/Case/StopRecording/{channelName}/{uid}/{sid}/{resourceId}/{caseId}`
                .replace(`{${"channelName"}}`, encodeURIComponent(String(channelName)))
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)))
                .replace(`{${"sid"}}`, encodeURIComponent(String(sid)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseTestBus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/TestBus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseTestNotify: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/TestNotify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CaseDTO} caseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseUpdateCase: async (caseDto: CaseDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseDto' is not null or undefined
            assertParamExists('caseUpdateCase', 'caseDto', caseDto)
            const localVarPath = `/Case/UpdateCase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseUpdateContact: async (contact: ContactDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contact' is not null or undefined
            assertParamExists('caseUpdateContact', 'contact', contact)
            const localVarPath = `/Case/UpdateContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBranchDTO} newBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseUpdateERPCForUID: async (newBranch: UpdateBranchDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newBranch' is not null or undefined
            assertParamExists('caseUpdateERPCForUID', 'newBranch', newBranch)
            const localVarPath = `/Case/UpdateERPCForUID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newBranch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProfileDTO} profile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseUpdateProfile: async (profile: ProfileDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profile' is not null or undefined
            assertParamExists('caseUpdateProfile', 'profile', profile)
            const localVarPath = `/Case/UpdateProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ErpcDTO} newBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseUpsertBranch: async (newBranch: ErpcDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newBranch' is not null or undefined
            assertParamExists('caseUpsertBranch', 'newBranch', newBranch)
            const localVarPath = `/Case/UpsertBranch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newBranch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyDTO} c 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseUpsertCompany: async (c: CompanyDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'c' is not null or undefined
            assertParamExists('caseUpsertCompany', 'c', c)
            const localVarPath = `/Case/UpsertCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(c, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SectorDTO} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseUpsertSector: async (sector: SectorDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sector' is not null or undefined
            assertParamExists('caseUpsertSector', 'sector', sector)
            const localVarPath = `/Case/UpsertSector`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaseApi - functional programming interface
 * @export
 */
export const CaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseAddAddress(address: AddressDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseAddAddress(address, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} branchId 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseAddAddressToBranch(branchId: number, address: AddressDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseAddAddressToBranch(branchId, address, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseid 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseAddAddressToCase(caseid: number, address: AddressDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseAddAddressToCase(caseid, address, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseAddAddressToGuid(address: AddressDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseAddAddressToGuid(address, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseAddContact(contact: ContactDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseAddContact(contact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactRelationship} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseAddContactRelation(contact: ContactRelationship, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseAddContactRelation(contact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<object>} [formdata] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseAddErpcMedia(formdata?: Array<object>, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseAddErpcMedia(formdata, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BulkStateDTO} stateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseBulkUpdateCaseStates(stateDto: BulkStateDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseBulkUpdateCaseStates(stateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BulkStateDTO} stateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseBulkUpdateErpcStates(stateDto: BulkStateDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseBulkUpdateErpcStates(stateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BulkUserStateDTO} stateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseBulkUpdateUserStates(stateDto: BulkUserStateDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseBulkUpdateUserStates(stateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CallConnectDTO} callConnectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseCallConnect(callConnectDto: CallConnectDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseCallConnect(callConnectDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseCancelCase(caseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseCancelCase(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CaseDTO} caseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseCreateCase(caseDto: CaseDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseCreateCase(caseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetBranch(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ErpcDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetBranch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} branchId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetBranchFileLinkById(branchId: number, fileId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetBranchFileLinkById(branchId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetBranches(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ErpcDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetBranches(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetBranchesForCompany(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ErpcDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetBranchesForCompany(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CaseBookFilterDTO} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetCaseBookCases(filter: CaseBookFilterDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseBookResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetCaseBookCases(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetCaseById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetCaseById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetCaseCategoryOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaseType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetCaseCategoryOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetCases(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetCases(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetCities(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CityDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetCities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetCompanies(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetCompany(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetCompany(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetConnectionString(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetConnectionString(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetContacts(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetContacts(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetDevicesForGuid(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetDevicesForGuid(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetErpcMembers(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShortUserDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetErpcMembers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetErpcOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ErpcDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetErpcOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetFileLinkById(caseId: number, fileId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetFileLinkById(caseId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetFileLinkByKey(guid: string, key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetFileLinkByKey(guid, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CaseFilterDTO} caseFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetFilteredCases(caseFilterDto: CaseFilterDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetFilteredCases(caseFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetLocationsForCase(caseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetLocationsForCase(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} branchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetMembersForBranch(branchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShortUserDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetMembersForBranch(branchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetOperatorPicForCase(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetOperatorPicForCase(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetProfile(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetProfile(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetRecording(prefix: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetRecording(prefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetRevenueCatSubscriptions(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RevenueCatResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetRevenueCatSubscriptions(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetSectorsForCompany(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectorDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetSectorsForCompany(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetTbxCatSubOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriptionOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetTbxCatSubOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetTbxSubscriptionsForUser(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TbxSubDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetTbxSubscriptionsForUser(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseListBranchFiles(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseListBranchFiles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseListCaseFiles(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseListCaseFiles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LocationNotificationDTO} location 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseLocationUpdate(location: LocationNotificationDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseLocationUpdate(location, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<object>} [formdata] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseNewDocument(formdata?: Array<object>, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseNewDocument(formdata, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<SubOptions>} subOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseOnPurchase(subOptions: Array<SubOptions>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseOnPurchase(subOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PanicDTO} panicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async casePanic(panicDto: PanicDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.casePanic(panicDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PauseCaseDTO} pauseCaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async casePauseCase(pauseCaseDto: PauseCaseDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.casePauseCase(pauseCaseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseProductsForGuid(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseProductsForGuid(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} casetypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseSaveCaseCategory(caseId: number, casetypeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseSaveCaseCategory(caseId, casetypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {JsonDocument} caseData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseSaveCaseData(id: number, caseData: JsonDocument, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseSaveCaseData(id, caseData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseSearch(searchStr: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SuggestionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseSearch(searchStr, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} partial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseSearchPredict(partial: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseSearchPredict(partial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseSetAsSponsored(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseSetAsSponsored(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} channelName 
         * @param {string} uid 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseStartRecording(channelName: string, uid: string, caseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartRecordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseStartRecording(channelName, uid, caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} channelName 
         * @param {string} uid 
         * @param {string} sid 
         * @param {string} resourceId 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseStopRecording(channelName: string, uid: string, sid: string, resourceId: string, caseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StopResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseStopRecording(channelName, uid, sid, resourceId, caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseTestBus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseTestBus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseTestNotify(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseTestNotify(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CaseDTO} caseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseUpdateCase(caseDto: CaseDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseUpdateCase(caseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseUpdateContact(contact: ContactDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseUpdateContact(contact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBranchDTO} newBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseUpdateERPCForUID(newBranch: UpdateBranchDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseUpdateERPCForUID(newBranch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProfileDTO} profile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseUpdateProfile(profile: ProfileDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseUpdateProfile(profile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ErpcDTO} newBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseUpsertBranch(newBranch: ErpcDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ErpcDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseUpsertBranch(newBranch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyDTO} c 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseUpsertCompany(c: CompanyDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseUpsertCompany(c, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SectorDTO} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseUpsertSector(sector: SectorDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectorDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseUpsertSector(sector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CaseApi - factory interface
 * @export
 */
export const CaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaseApiFp(configuration)
    return {
        /**
         * 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAddAddress(address: AddressDTO, options?: any): AxiosPromise<ProfileDTO> {
            return localVarFp.caseAddAddress(address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} branchId 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAddAddressToBranch(branchId: number, address: AddressDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.caseAddAddressToBranch(branchId, address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseid 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAddAddressToCase(caseid: number, address: AddressDTO, options?: any): AxiosPromise<CaseDTO> {
            return localVarFp.caseAddAddressToCase(caseid, address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAddAddressToGuid(address: AddressDTO, options?: any): AxiosPromise<ProfileDTO> {
            return localVarFp.caseAddAddressToGuid(address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAddContact(contact: ContactDTO, options?: any): AxiosPromise<string> {
            return localVarFp.caseAddContact(contact, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactRelationship} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAddContactRelation(contact: ContactRelationship, options?: any): AxiosPromise<ContactDTO> {
            return localVarFp.caseAddContactRelation(contact, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<object>} [formdata] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseAddErpcMedia(formdata?: Array<object>, file?: any, options?: any): AxiosPromise<any> {
            return localVarFp.caseAddErpcMedia(formdata, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BulkStateDTO} stateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseBulkUpdateCaseStates(stateDto: BulkStateDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.caseBulkUpdateCaseStates(stateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BulkStateDTO} stateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseBulkUpdateErpcStates(stateDto: BulkStateDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.caseBulkUpdateErpcStates(stateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BulkUserStateDTO} stateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseBulkUpdateUserStates(stateDto: BulkUserStateDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.caseBulkUpdateUserStates(stateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CallConnectDTO} callConnectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseCallConnect(callConnectDto: CallConnectDTO, options?: any): AxiosPromise<string> {
            return localVarFp.caseCallConnect(callConnectDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseCancelCase(caseId: number, options?: any): AxiosPromise<string> {
            return localVarFp.caseCancelCase(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CaseDTO} caseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseCreateCase(caseDto: CaseDTO, options?: any): AxiosPromise<CaseDTO> {
            return localVarFp.caseCreateCase(caseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetBranch(id: number, options?: any): AxiosPromise<ErpcDTO> {
            return localVarFp.caseGetBranch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} branchId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetBranchFileLinkById(branchId: number, fileId: number, options?: any): AxiosPromise<any> {
            return localVarFp.caseGetBranchFileLinkById(branchId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetBranches(options?: any): AxiosPromise<Array<ErpcDTO>> {
            return localVarFp.caseGetBranches(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetBranchesForCompany(companyId: number, options?: any): AxiosPromise<Array<ErpcDTO>> {
            return localVarFp.caseGetBranchesForCompany(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CaseBookFilterDTO} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCaseBookCases(filter: CaseBookFilterDTO, options?: any): AxiosPromise<CaseBookResponse> {
            return localVarFp.caseGetCaseBookCases(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCaseById(id: number, options?: any): AxiosPromise<CaseDTO> {
            return localVarFp.caseGetCaseById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCaseCategoryOptions(options?: any): AxiosPromise<Array<CaseType>> {
            return localVarFp.caseGetCaseCategoryOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCases(options?: any): AxiosPromise<Array<CaseDTO>> {
            return localVarFp.caseGetCases(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCities(options?: any): AxiosPromise<Array<CityDTO>> {
            return localVarFp.caseGetCities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCompanies(options?: any): AxiosPromise<Array<CompanyDTO>> {
            return localVarFp.caseGetCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCompany(id: number, options?: any): AxiosPromise<CompanyDTO> {
            return localVarFp.caseGetCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetConnectionString(options?: any): AxiosPromise<string> {
            return localVarFp.caseGetConnectionString(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetContacts(guid: string, options?: any): AxiosPromise<Array<ContactDTO>> {
            return localVarFp.caseGetContacts(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetDevicesForGuid(guid: string, options?: any): AxiosPromise<Array<DeviceDTO>> {
            return localVarFp.caseGetDevicesForGuid(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetErpcMembers(id: number, options?: any): AxiosPromise<Array<ShortUserDTO>> {
            return localVarFp.caseGetErpcMembers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetErpcOptions(options?: any): AxiosPromise<Array<ErpcDTO>> {
            return localVarFp.caseGetErpcOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetFileLinkById(caseId: number, fileId: number, options?: any): AxiosPromise<any> {
            return localVarFp.caseGetFileLinkById(caseId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetFileLinkByKey(guid: string, key: string, options?: any): AxiosPromise<any> {
            return localVarFp.caseGetFileLinkByKey(guid, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CaseFilterDTO} caseFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetFilteredCases(caseFilterDto: CaseFilterDTO, options?: any): AxiosPromise<Array<CaseDTO>> {
            return localVarFp.caseGetFilteredCases(caseFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetLocationsForCase(caseId: number, options?: any): AxiosPromise<Array<LocationDto>> {
            return localVarFp.caseGetLocationsForCase(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} branchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetMembersForBranch(branchId: number, options?: any): AxiosPromise<Array<ShortUserDTO>> {
            return localVarFp.caseGetMembersForBranch(branchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetOperatorPicForCase(id: number, options?: any): AxiosPromise<OperatorInfo> {
            return localVarFp.caseGetOperatorPicForCase(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetProfile(guid: string, options?: any): AxiosPromise<ProfileDTO> {
            return localVarFp.caseGetProfile(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetRecording(prefix: string, options?: any): AxiosPromise<any> {
            return localVarFp.caseGetRecording(prefix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetRevenueCatSubscriptions(guid: string, options?: any): AxiosPromise<RevenueCatResponse> {
            return localVarFp.caseGetRevenueCatSubscriptions(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetSectorsForCompany(companyId: number, options?: any): AxiosPromise<Array<SectorDTO>> {
            return localVarFp.caseGetSectorsForCompany(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetTbxCatSubOptions(options?: any): AxiosPromise<Array<SubscriptionOption>> {
            return localVarFp.caseGetTbxCatSubOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetTbxSubscriptionsForUser(guid: string, options?: any): AxiosPromise<Array<TbxSubDto>> {
            return localVarFp.caseGetTbxSubscriptionsForUser(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseListBranchFiles(id: number, options?: any): AxiosPromise<Array<DocumentDTO>> {
            return localVarFp.caseListBranchFiles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseListCaseFiles(id: number, options?: any): AxiosPromise<Array<DocumentDTO>> {
            return localVarFp.caseListCaseFiles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationNotificationDTO} location 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseLocationUpdate(location: LocationNotificationDTO, options?: any): AxiosPromise<string> {
            return localVarFp.caseLocationUpdate(location, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<object>} [formdata] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseNewDocument(formdata?: Array<object>, file?: any, options?: any): AxiosPromise<string> {
            return localVarFp.caseNewDocument(formdata, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<SubOptions>} subOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseOnPurchase(subOptions: Array<SubOptions>, options?: any): AxiosPromise<string> {
            return localVarFp.caseOnPurchase(subOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PanicDTO} panicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casePanic(panicDto: PanicDTO, options?: any): AxiosPromise<CaseDTO> {
            return localVarFp.casePanic(panicDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PauseCaseDTO} pauseCaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casePauseCase(pauseCaseDto: PauseCaseDTO, options?: any): AxiosPromise<string> {
            return localVarFp.casePauseCase(pauseCaseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseProductsForGuid(guid: string, options?: any): AxiosPromise<Array<ProductDTO>> {
            return localVarFp.caseProductsForGuid(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} casetypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseSaveCaseCategory(caseId: number, casetypeId: number, options?: any): AxiosPromise<string> {
            return localVarFp.caseSaveCaseCategory(caseId, casetypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {JsonDocument} caseData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseSaveCaseData(id: number, caseData: JsonDocument, options?: any): AxiosPromise<string> {
            return localVarFp.caseSaveCaseData(id, caseData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseSearch(searchStr: string, options?: any): AxiosPromise<Array<SuggestionDTO>> {
            return localVarFp.caseSearch(searchStr, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} partial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseSearchPredict(partial: string, options?: any): AxiosPromise<Array<UserDTO>> {
            return localVarFp.caseSearchPredict(partial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseSetAsSponsored(guid: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.caseSetAsSponsored(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} channelName 
         * @param {string} uid 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseStartRecording(channelName: string, uid: string, caseId: number, options?: any): AxiosPromise<StartRecordResponse> {
            return localVarFp.caseStartRecording(channelName, uid, caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} channelName 
         * @param {string} uid 
         * @param {string} sid 
         * @param {string} resourceId 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseStopRecording(channelName: string, uid: string, sid: string, resourceId: string, caseId: number, options?: any): AxiosPromise<StopResponse> {
            return localVarFp.caseStopRecording(channelName, uid, sid, resourceId, caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseTestBus(options?: any): AxiosPromise<string> {
            return localVarFp.caseTestBus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseTestNotify(options?: any): AxiosPromise<string> {
            return localVarFp.caseTestNotify(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CaseDTO} caseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseUpdateCase(caseDto: CaseDTO, options?: any): AxiosPromise<CaseDTO> {
            return localVarFp.caseUpdateCase(caseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseUpdateContact(contact: ContactDTO, options?: any): AxiosPromise<ContactDTO> {
            return localVarFp.caseUpdateContact(contact, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBranchDTO} newBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseUpdateERPCForUID(newBranch: UpdateBranchDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.caseUpdateERPCForUID(newBranch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileDTO} profile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseUpdateProfile(profile: ProfileDTO, options?: any): AxiosPromise<ProfileDTO> {
            return localVarFp.caseUpdateProfile(profile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ErpcDTO} newBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseUpsertBranch(newBranch: ErpcDTO, options?: any): AxiosPromise<ErpcDTO> {
            return localVarFp.caseUpsertBranch(newBranch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyDTO} c 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseUpsertCompany(c: CompanyDTO, options?: any): AxiosPromise<CompanyDTO> {
            return localVarFp.caseUpsertCompany(c, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SectorDTO} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseUpsertSector(sector: SectorDTO, options?: any): AxiosPromise<SectorDTO> {
            return localVarFp.caseUpsertSector(sector, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CaseApi - object-oriented interface
 * @export
 * @class CaseApi
 * @extends {BaseAPI}
 */
export class CaseApi extends BaseAPI {
    /**
     * 
     * @param {AddressDTO} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseAddAddress(address: AddressDTO, options?: any) {
        return CaseApiFp(this.configuration).caseAddAddress(address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} branchId 
     * @param {AddressDTO} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseAddAddressToBranch(branchId: number, address: AddressDTO, options?: any) {
        return CaseApiFp(this.configuration).caseAddAddressToBranch(branchId, address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseid 
     * @param {AddressDTO} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseAddAddressToCase(caseid: number, address: AddressDTO, options?: any) {
        return CaseApiFp(this.configuration).caseAddAddressToCase(caseid, address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddressDTO} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseAddAddressToGuid(address: AddressDTO, options?: any) {
        return CaseApiFp(this.configuration).caseAddAddressToGuid(address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactDTO} contact 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseAddContact(contact: ContactDTO, options?: any) {
        return CaseApiFp(this.configuration).caseAddContact(contact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactRelationship} contact 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseAddContactRelation(contact: ContactRelationship, options?: any) {
        return CaseApiFp(this.configuration).caseAddContactRelation(contact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<object>} [formdata] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseAddErpcMedia(formdata?: Array<object>, file?: any, options?: any) {
        return CaseApiFp(this.configuration).caseAddErpcMedia(formdata, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BulkStateDTO} stateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseBulkUpdateCaseStates(stateDto: BulkStateDTO, options?: any) {
        return CaseApiFp(this.configuration).caseBulkUpdateCaseStates(stateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BulkStateDTO} stateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseBulkUpdateErpcStates(stateDto: BulkStateDTO, options?: any) {
        return CaseApiFp(this.configuration).caseBulkUpdateErpcStates(stateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BulkUserStateDTO} stateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseBulkUpdateUserStates(stateDto: BulkUserStateDTO, options?: any) {
        return CaseApiFp(this.configuration).caseBulkUpdateUserStates(stateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CallConnectDTO} callConnectDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseCallConnect(callConnectDto: CallConnectDTO, options?: any) {
        return CaseApiFp(this.configuration).caseCallConnect(callConnectDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseCancelCase(caseId: number, options?: any) {
        return CaseApiFp(this.configuration).caseCancelCase(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CaseDTO} caseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseCreateCase(caseDto: CaseDTO, options?: any) {
        return CaseApiFp(this.configuration).caseCreateCase(caseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetBranch(id: number, options?: any) {
        return CaseApiFp(this.configuration).caseGetBranch(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} branchId 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetBranchFileLinkById(branchId: number, fileId: number, options?: any) {
        return CaseApiFp(this.configuration).caseGetBranchFileLinkById(branchId, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetBranches(options?: any) {
        return CaseApiFp(this.configuration).caseGetBranches(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetBranchesForCompany(companyId: number, options?: any) {
        return CaseApiFp(this.configuration).caseGetBranchesForCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CaseBookFilterDTO} filter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetCaseBookCases(filter: CaseBookFilterDTO, options?: any) {
        return CaseApiFp(this.configuration).caseGetCaseBookCases(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetCaseById(id: number, options?: any) {
        return CaseApiFp(this.configuration).caseGetCaseById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetCaseCategoryOptions(options?: any) {
        return CaseApiFp(this.configuration).caseGetCaseCategoryOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetCases(options?: any) {
        return CaseApiFp(this.configuration).caseGetCases(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetCities(options?: any) {
        return CaseApiFp(this.configuration).caseGetCities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetCompanies(options?: any) {
        return CaseApiFp(this.configuration).caseGetCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetCompany(id: number, options?: any) {
        return CaseApiFp(this.configuration).caseGetCompany(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetConnectionString(options?: any) {
        return CaseApiFp(this.configuration).caseGetConnectionString(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetContacts(guid: string, options?: any) {
        return CaseApiFp(this.configuration).caseGetContacts(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetDevicesForGuid(guid: string, options?: any) {
        return CaseApiFp(this.configuration).caseGetDevicesForGuid(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetErpcMembers(id: number, options?: any) {
        return CaseApiFp(this.configuration).caseGetErpcMembers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetErpcOptions(options?: any) {
        return CaseApiFp(this.configuration).caseGetErpcOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseId 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetFileLinkById(caseId: number, fileId: number, options?: any) {
        return CaseApiFp(this.configuration).caseGetFileLinkById(caseId, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetFileLinkByKey(guid: string, key: string, options?: any) {
        return CaseApiFp(this.configuration).caseGetFileLinkByKey(guid, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CaseFilterDTO} caseFilterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetFilteredCases(caseFilterDto: CaseFilterDTO, options?: any) {
        return CaseApiFp(this.configuration).caseGetFilteredCases(caseFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetLocationsForCase(caseId: number, options?: any) {
        return CaseApiFp(this.configuration).caseGetLocationsForCase(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} branchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetMembersForBranch(branchId: number, options?: any) {
        return CaseApiFp(this.configuration).caseGetMembersForBranch(branchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetOperatorPicForCase(id: number, options?: any) {
        return CaseApiFp(this.configuration).caseGetOperatorPicForCase(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetProfile(guid: string, options?: any) {
        return CaseApiFp(this.configuration).caseGetProfile(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prefix 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetRecording(prefix: string, options?: any) {
        return CaseApiFp(this.configuration).caseGetRecording(prefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetRevenueCatSubscriptions(guid: string, options?: any) {
        return CaseApiFp(this.configuration).caseGetRevenueCatSubscriptions(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetSectorsForCompany(companyId: number, options?: any) {
        return CaseApiFp(this.configuration).caseGetSectorsForCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetTbxCatSubOptions(options?: any) {
        return CaseApiFp(this.configuration).caseGetTbxCatSubOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetTbxSubscriptionsForUser(guid: string, options?: any) {
        return CaseApiFp(this.configuration).caseGetTbxSubscriptionsForUser(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseListBranchFiles(id: number, options?: any) {
        return CaseApiFp(this.configuration).caseListBranchFiles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseListCaseFiles(id: number, options?: any) {
        return CaseApiFp(this.configuration).caseListCaseFiles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationNotificationDTO} location 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseLocationUpdate(location: LocationNotificationDTO, options?: any) {
        return CaseApiFp(this.configuration).caseLocationUpdate(location, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<object>} [formdata] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseNewDocument(formdata?: Array<object>, file?: any, options?: any) {
        return CaseApiFp(this.configuration).caseNewDocument(formdata, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<SubOptions>} subOptions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseOnPurchase(subOptions: Array<SubOptions>, options?: any) {
        return CaseApiFp(this.configuration).caseOnPurchase(subOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PanicDTO} panicDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public casePanic(panicDto: PanicDTO, options?: any) {
        return CaseApiFp(this.configuration).casePanic(panicDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PauseCaseDTO} pauseCaseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public casePauseCase(pauseCaseDto: PauseCaseDTO, options?: any) {
        return CaseApiFp(this.configuration).casePauseCase(pauseCaseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseProductsForGuid(guid: string, options?: any) {
        return CaseApiFp(this.configuration).caseProductsForGuid(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseId 
     * @param {number} casetypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseSaveCaseCategory(caseId: number, casetypeId: number, options?: any) {
        return CaseApiFp(this.configuration).caseSaveCaseCategory(caseId, casetypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {JsonDocument} caseData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseSaveCaseData(id: number, caseData: JsonDocument, options?: any) {
        return CaseApiFp(this.configuration).caseSaveCaseData(id, caseData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} searchStr 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseSearch(searchStr: string, options?: any) {
        return CaseApiFp(this.configuration).caseSearch(searchStr, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} partial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseSearchPredict(partial: string, options?: any) {
        return CaseApiFp(this.configuration).caseSearchPredict(partial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseSetAsSponsored(guid: string, options?: any) {
        return CaseApiFp(this.configuration).caseSetAsSponsored(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} channelName 
     * @param {string} uid 
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseStartRecording(channelName: string, uid: string, caseId: number, options?: any) {
        return CaseApiFp(this.configuration).caseStartRecording(channelName, uid, caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} channelName 
     * @param {string} uid 
     * @param {string} sid 
     * @param {string} resourceId 
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseStopRecording(channelName: string, uid: string, sid: string, resourceId: string, caseId: number, options?: any) {
        return CaseApiFp(this.configuration).caseStopRecording(channelName, uid, sid, resourceId, caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseTestBus(options?: any) {
        return CaseApiFp(this.configuration).caseTestBus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseTestNotify(options?: any) {
        return CaseApiFp(this.configuration).caseTestNotify(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CaseDTO} caseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseUpdateCase(caseDto: CaseDTO, options?: any) {
        return CaseApiFp(this.configuration).caseUpdateCase(caseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactDTO} contact 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseUpdateContact(contact: ContactDTO, options?: any) {
        return CaseApiFp(this.configuration).caseUpdateContact(contact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateBranchDTO} newBranch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseUpdateERPCForUID(newBranch: UpdateBranchDTO, options?: any) {
        return CaseApiFp(this.configuration).caseUpdateERPCForUID(newBranch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileDTO} profile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseUpdateProfile(profile: ProfileDTO, options?: any) {
        return CaseApiFp(this.configuration).caseUpdateProfile(profile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ErpcDTO} newBranch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseUpsertBranch(newBranch: ErpcDTO, options?: any) {
        return CaseApiFp(this.configuration).caseUpsertBranch(newBranch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyDTO} c 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseUpsertCompany(c: CompanyDTO, options?: any) {
        return CaseApiFp(this.configuration).caseUpsertCompany(c, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SectorDTO} sector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseUpsertSector(sector: SectorDTO, options?: any) {
        return CaseApiFp(this.configuration).caseUpsertSector(sector, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testHealthCheck: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testHealthCheck(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testHealthCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testHealthCheck(options?: any): AxiosPromise<any> {
            return localVarFp.testHealthCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testHealthCheck(options?: any) {
        return TestApiFp(this.configuration).testHealthCheck(options).then((request) => request(this.axios, this.basePath));
    }
}


