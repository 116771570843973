
























































































import {vxm} from "@/store";
import {Component, Vue, Watch} from 'vue-property-decorator';
import ContactCard from "@/views/widgets/ContactCard.vue";
import MyProduct from "@/views/widgets/MyProduct.vue";
import SmileyRater from "@/views/widgets/SmileyRater.vue";
import SmileyRater2 from "@/views/widgets/SmileyRater2.vue";
import {
  Configuration,
  ProductDTO,
  ProfileApiFactory,
  RevenueCatResponse, Subscriber,
  Subscription, SubscriptionOption, TbxSubDto
} from "@shared_vue/openapi/myvaultapi";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";



@Component({
    components:{
      SmileyRater2,
      SmileyRater,
      MyProduct,
      ContactCard,
      WaitModal

    }
})

export default class MyAccountPage extends Vue {
    private ui = vxm.ui;
    private auth = vxm.auth;
    private profileApi = ProfileApiFactory(<Configuration>{basePath:process.env.VUE_APP_MYVAULT_API_URL},process.env.VUE_APP_MYVAULT_API_URL);
    private subsCore: Array<SubscriptionOption> = []
    private myTbxSubs: Array<TbxSubDto> = []
    private revenueCatSubs: Array<string> = []
    products: string[] = [];
    private showWait: boolean = false;
    private waitABit = false;
    private validSubs: string[] = [];

  private isSubscriptionValid(subscription: Subscription): boolean {
    console.log('checking sub...')
    const currentDate = new Date();
    const expirationDate = new Date(subscription.expiresDate);
    if (currentDate < expirationDate) {
      console.log('not expired')
      return true;
    } else {
      console.log('expired');
      return false;
    }
  }

  /**
   * Checks if the subscriber has any valid subscription.
   * @param subscriber The subscriber to check.
   * @returns true if any subscription is valid, false otherwise.
   */
  private hasValidSubscription(subscriber: Subscriber): boolean {
    for (const key in subscriber.subscriptions) {
      if (this.isSubscriptionValid(subscriber.subscriptions[key])) {
        return true;
      }
    }
    return false;
  }


  get hasSubs(): boolean {
    return this.validSubs.length>0;
  }

    private getHelp(){
      // show modal
    }

  @Watch('auth.idToken')
  onIdToken(val: boolean, oldval: boolean) {
    console.log('onidtoken')
    if (this.waitABit){
      this.waitABit=false;
      this.fetchAll();
    }
  }

    mounted(){
      this.showWait = false;
      const outerThis = this;
      //hey don't forget a nice plz wait modal
      //do we want reminders in the store? I'm not really sure
      //wait for valid user if this was refreshed (I do not save token in store)
      if (!vxm.auth.idToken){ //wait a bit in case it is coming
        this.waitABit=true;
      } else {
        this.fetchAll();
      }

    }

  getRevenueCatId(productName: string): string | undefined {
    const option = this.subsCore.find(option => option.name === productName);
    return option ? option.revenueCatId : undefined;
  }

  getProductName(revenueCatId: string): string | undefined {
    const option = this.subsCore.find(option => option.revenueCatId.includes(revenueCatId));
    return option ? option.name : undefined;
  }

  getProductId(revenueCatId: string): number | undefined {
    const option = this.subsCore.find(option => option.revenueCatId === revenueCatId);
    return option ? option.id : undefined;
  }
  
    private async fetchAll(){ // we need to abstract this to something
      this.showWait=true;
      try {
        this.subsCore = (await this.profileApi.profileGetTbxSubOptions()).data;
        this.myTbxSubs = (await this.profileApi.profileGetTbxSubscriptions()).data;
        this.myTbxSubs.forEach(a=>{
          this.products.push(a.name);
        })
        let rcsubs:RevenueCatResponse = (await this.profileApi.profileGetRevenueCatSubscriptions()).data;
        console.log('got subs');
        if (rcsubs && rcsubs.subscriber) {
          const subscriber = rcsubs.subscriber;
          for (const key in subscriber.subscriptions) {
            if (this.isSubscriptionValid(subscriber.subscriptions[key])) {
              this.revenueCatSubs.push(<string>this.getProductName((subscriber.subscriptions[key]).productPlanIdentifier));
            }
          }
        }
        this.validSubs = this.revenueCatSubs.concat(this.products);
      } catch (error){
        //no products
      } finally {
        this.showWait=false;
      }
    }

    private baseProduct = {
      id: 0,
      title: 'MyVault Profile',
      description: 'some description',
      image: 'https://via.placeholder.com/150',
      link: '#',
      quantity:1,
      paymentPeriod:'Free',
      price: 0.00,
      formattedPrice: 'R0.00'
    }
  // private myProducts = [
  //   {
  //     id: 1,
  //     title: 'AAA Response Premium',
  //     description: 'some description',
  //     image: 'https://via.placeholder.com/150',
  //     link: '/',
  //     quantity:1,
  //     paymentPeriod:'Per Month',
  //     price: 10.00,
  //     formattedPrice: 'R10.00'
  //   },
  //   {
  //     id: 2,
  //     title: 'Advanced CoDriver Assist',
  //     description: 'some description',
  //     image: 'https://via.placeholder.com/150',
  //     link: '/',
  //     quantity:1,
  //     paymentPeriod:'Per Month',
  //     price: 20.00,
  //     formattedPrice: 'R20.00'
  //   },
  //   {
  //     id: 3,
  //     title: 'Sacvia Basic',
  //     description: 'some description',
  //     image: 'https://via.placeholder.com/150',
  //     link: '/',
  //     quantity:1,
  //     paymentPeriod:'Per Month',
  //     price: 30.00,
  //     formattedPrice: 'R30.00'
  //   },
  //   {
  //     id: 4,
  //     title: 'Sacvia Advanced',
  //     description: 'some description',
  //     image: 'https://via.placeholder.com/150',
  //     link: '/',
  //     quantity:1,
  //     paymentPeriod:'Per Month',
  //     price: 40.00,
  //     formattedPrice: 'R40.00'
  //   }
  //
  // ]

  // private totalSubs = this.myProducts.length;
  // private totalSubsCost = this.myProducts.reduce((a,b)=>a+b.price,0);

  private navigate(where: string){
    this.$router.push(where);
  }

}
